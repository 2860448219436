import { Box, Icon, Text } from '@nike/eds';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ImageViewer.module.scss';

interface ImageViewerProps {
	imgPath?: string;
	altMessage?: string;
	classNames?: string;
}

export const ImageViewer = ({ imgPath, altMessage, classNames }: ImageViewerProps) => {
	const { t } = useTranslation();
	const [img, setImg] = useState('');
	const [isImgLoading, setIsImgLoading] = useState(true);

	useEffect(() => {
		if (imgPath) {
			setIsImgLoading(true);
			const container = new Image();
			container.src = imgPath;
			container.onload = () => {
				setImg(imgPath);
				setIsImgLoading(false);
			};
			container.onerror = () => setIsImgLoading(false);
		} else {
			setIsImgLoading(false);
		}
	}, [imgPath]);

	const image = useMemo(() => {
		if (!img) {
			return (
				<Box className="eds-flex eds-flex--direction-column eds-flex--align-items-center eds-flex--justify-content-center w200 h200">
					<Icon className="eds-color--grey-1 eds-spacing--mb-16" name="ImagenoImage" size="l" />
					<Text className="eds-color--grey-1" font="title-5">
						{t('common.no_image_message')}
					</Text>
				</Box>
			);
		}

		return (
			<img
				className={styles['container-img']}
				src={img}
				loading="lazy"
				alt={altMessage ?? t('common.no_caption_message')}
			/>
		);
	}, [altMessage, img, t]);

	return (
		<Box data-testid="image-viewer" className={`${styles['container']} ${classNames ?? ''}`}>
			{isImgLoading ? <div className={styles['container-img']} /> : image}
		</Box>
	);
};
