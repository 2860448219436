import { NavItem } from '@nike/eds';

import { API_DOCS_ROUTE, CAPABILITIES_ROUTE, USER_DASHBOARD_ROUTE } from 'services/constants/routes';

const verticalNavItems: NavItem[] = [
	{
		id: CAPABILITIES_ROUTE,
		icon: 'Data',
		label: 'pages.capabilities.header_label',
		active: false,
	},
	{
		id: USER_DASHBOARD_ROUTE,
		icon: 'Analytics',
		label: 'pages.user_dashboard.current_page_label',
		active: false,
	},
	{
		id: API_DOCS_ROUTE,
		icon: 'Whiteboard',
		label: 'pages.api_docs.header_label',
		active: false,
	},
];

export const getVerticalNavItems = (path: string) =>
	verticalNavItems.map((item) => {
		item.active = path?.startsWith(item.id);
		return item;
	});
