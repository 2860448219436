import { Box, Divider, Icon, Menu, MenuItem, NavEvent, NavItem, Text } from '@nike/eds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { HOME_ROUTE } from 'services/constants/routes';

import styles from './LeftSidebar.module.scss';

interface LeftSidebarProps {
	appName: string;
	items: NavItem[];
	onNavigate: (event: NavEvent) => void;
}

export const LeftSidebar = ({ appName, items, onNavigate }: LeftSidebarProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const currentYear = new Date().getFullYear();

	const renderMenuItem = (item: NavItem) => {
		const { active, label, icon } = item;
		const className = active ? 'eds-color--white' : 'eds-color--secondary';

		return (
			<MenuItem
				key={label}
				beforeSlot={<Icon className={`eds-spacing--mr-16 ${className}`} name={icon as string} size="m" />}
				onClick={() => onNavigate(item)}
			>
				<Text className={className} font="body-1">
					{label}
				</Text>
			</MenuItem>
		);
	};

	const handleNavigateToHome = () => navigate(HOME_ROUTE);

	return (
		<Box data-testid="vertical-nav" className={`eds--dark ${styles['container']}`}>
			<Box>
				<Menu
					isOpen={true}
					className="full-width"
					headerSlot={
						<Box className={`eds-spacing--mb-32 cursor--pointer ${styles['logo']}`} onClick={handleNavigateToHome}>
							<Box className="eds-flex eds-flex--align-items-center eds-spacing--mt-8">
								<Icon
									className="eds-spacing--mr-8"
									name="NikeApp"
									color="white"
									style={{ width: '56px', height: '56px' }}
									aria-label={appName}
								/>
								<Text font="display-5">{t('common.partner_hub')}</Text>
							</Box>
							<Box className={`${styles['logo-sub-title']}`}>
								<Text font="title-5">{t('common.app_name')}</Text>
							</Box>
						</Box>
					}
					bodySlot={
						<>
							<Divider className="eds-spacing--mb-16" />
							{items.map(renderMenuItem)}
							<Divider className="eds-spacing--mt-16" />
						</>
					}
				/>
			</Box>
			<Text color="white">{t('common.copyright', { currentYear })}</Text>
		</Box>
	);
};
