import { Text } from '@nike/eds';
import { useTranslation } from 'react-i18next';

import PartnerLogo from 'assets/imgs/logo-ph-black.png';

import styles from './AppLogo.module.scss';

interface AppLogoProps {
	className?: string;
}

export const AppLogo = ({ className }: AppLogoProps) => {
	const { t } = useTranslation();

	return (
		<div data-testid="app-logo" className={`${styles['container']} + ${className ?? ''}`}>
			<img src={PartnerLogo} loading="lazy" alt={t('pages.loggin.partners_logo_alt_label')} />
			<Text font="display-2" color="white">
				{t('common.partner_hub')}
			</Text>
			<Text color="white">{t('common.app_name')}</Text>
		</div>
	);
};
