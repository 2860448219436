import { ReactNode, useEffect, useMemo } from 'react';

import { InternalLayout } from 'components/Layouts/InternalLayout/InternalLayout';
import { useDispatch, useSelector } from 'config/redux/store';
import { getPartners } from 'services/api/partner-roster/PartnerRosterApi';
import { selectAuthType } from 'services/store/auth/AuthSelectors';
import { AuthType } from 'services/store/auth/AuthSlice';

import 'scss/main.scss';

interface AppProps {
	children: ReactNode;
}

export const App = ({ children }: AppProps) => {
	const dispatch = useDispatch();
	const authType = useSelector(selectAuthType);

	useEffect(() => {
		dispatch(getPartners());
	}, [dispatch]);

	const content = useMemo(() => {
		if (authType === AuthType.INTERNAL) {
			return <InternalLayout>{children}</InternalLayout>;
		}
	}, [authType, children]);

	return <>{content}</>;
};
