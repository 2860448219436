import { Box, Label, Toggle } from '@nike/eds';
import { v4 as uuidv4 } from 'uuid';

export interface FormToggleFieldProps {
	label: string;
	error: string;
	description: string;
	isChecked: boolean;
	onToggle: (value: boolean) => void;
}

export const FormToggleField = ({ isChecked, error, label, description, onToggle }: FormToggleFieldProps) => {
	return (
		<Box className="eds-flex eds-flex--direction-column eds-flex--justify-content-center" style={{ height: '116px' }}>
			<Box>
				<Toggle
					className="eds-type--title-6 eds-spacing--mb-8"
					id={uuidv4()}
					label={label}
					checked={isChecked}
					onChange={() => onToggle(!isChecked)}
				/>
				{description && <Label className="eds-color--text-secondary eds-type--body-3">{description}</Label>}
				{error && (
					<div className="eds-input-message-group">
						<Label className="eds-input-message-group__error-message">{error}</Label>
					</div>
				)}
			</Box>
		</Box>
	);
};
