export const HOME_ROUTE = '/';
export const API_DOCS_ROUTE = '/api-docs';
export const CAPABILITIES_ROUTE = '/capabilities';
export const USER_DASHBOARD_ROUTE = '/dashboard';
export const CAPABILITY_DETAILS_ROUTE = '/capabilities/:capabilityId';
export const INTEGRATION_PATTERN_CONFIGURATION_ROUTE =
	'/capabilities/:capabilityId/integration-patterns/:integrationPatternId/configuration';

export const getCapabilityDetailsRoute = (capabilityId: string) => `${CAPABILITIES_ROUTE}/${capabilityId}`;
export const getIntegrationPatternConfigurationRoute = (capabilityId: string, integrationPatternId: string) =>
	`${CAPABILITIES_ROUTE}/${capabilityId}/integration-patterns/${integrationPatternId}/configuration`;
