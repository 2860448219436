import { Button, Text } from '@nike/eds';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AppLogo } from 'components/common/AppLogo';
import { useDispatch } from 'config/redux/store';
import { AuthType, startInitAuth } from 'services/store/auth/AuthSlice';

import styles from './LoginPage.module.scss';

interface LoginPageProps {
	authType: AuthType;
	isLoading: boolean;
}

export const LoginPage = ({ authType, isLoading }: LoginPageProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const currentYear = new Date().getFullYear();

	const handleLogin = useCallback(() => {
		if (authType === AuthType.INTERNAL) {
			dispatch(startInitAuth());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [authType]);

	return (
		<div data-testid="login-page-testid" className={styles['container']}>
			<div className={styles['container-content']}>
				<section className={styles['login']}>
					<AppLogo className="eds-spacing--mb-16" />
					<div className={styles['action']}>
						<Button type="button" size="medium" variant="primary" disabled={isLoading} onClick={handleLogin}>
							{t('pages.login.login_btn_label')}
						</Button>
					</div>
				</section>
				<footer className={styles['footer']}>
					<Text data-testid="copyright" color="white">
						{t('common.copyright', { currentYear })}
					</Text>
				</footer>
			</div>
		</div>
	);
};
