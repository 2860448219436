import { Box, Text } from '@nike/eds';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomCard } from 'components/common/CustomCard';
import { Loader } from 'components/common/Loader';
import { useDispatch, useSelector } from 'config/redux/store';
import { getCapabilities } from 'services/api/mos-configurator/MosConfiguratorApi';
import { getCapabilityDetailsRoute } from 'services/constants/routes';
import {
	selectCapabilities,
	selectCapabilitiesError,
	selectCapabilitiesFilterOptions,
	selectIsLoadingCapabilities,
} from 'services/store/capabilities/CapabilitiesSelectors';
import { clearCapabilitiesError } from 'services/store/capabilities/CapabilitiesSlice';
import { Capability } from 'services/types/Capability';
import { SortDirection } from 'services/types/common';
import { useDebounce } from 'utils/hooks/debounce';
import { useSnack } from 'utils/hooks/snack';
import { getCardHeightClassName, getColumnsCount } from 'utils/utils';

export const CapabilitiesPage = () => {
	const snack = useSnack();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const error = useSelector(selectCapabilitiesError);
	const capabilities = useSelector(selectCapabilities);
	const isLoading = useSelector(selectIsLoadingCapabilities);
	const { sortDirection, sortField, searchFilter } = useSelector(selectCapabilitiesFilterOptions);
	const debouncedSearchFilter = useDebounce(searchFilter);

	useEffect(() => {
		if (!capabilities.length) {
			dispatch(getCapabilities());
		}
	}, [capabilities, dispatch]);

	useEffect(() => {
		if (error) {
			snack.error(error);
			dispatch(clearCapabilitiesError());
		}
	}, [error, snack, dispatch]);

	const handleNavigate = (capabilityId: string) => () => navigate(getCapabilityDetailsRoute(capabilityId));

	const content = useMemo(() => {
		const filter = (debouncedSearchFilter && debouncedSearchFilter.toLowerCase()) ?? '';

		const filteredCapabilities = capabilities
			.filter((capability) => {
				const field = capability[sortField as keyof Capability] as string;

				return field.toLowerCase().includes(filter);
			})
			.sort((firstRow, secondRow) => {
				const firstField = firstRow[sortField as keyof Capability]?.toString() ?? '';
				const secondField = secondRow[sortField as keyof Capability]?.toString() ?? '';
				if (sortDirection === SortDirection.ASC) {
					return firstField?.localeCompare(secondField);
				}

				return secondField?.localeCompare(firstField);
			});

		if (!filteredCapabilities?.length && !isLoading) {
			return (
				<Box className="eds-flex eds-flex--align-items-center eds-flex--justify-content-center">
					<Text font="title-3">{t('pages.capabilities.no_items_found_message')}</Text>
				</Box>
			);
		}
		const columns = getColumnsCount(capabilities.length);
		const cardHeight = getCardHeightClassName(capabilities.length);

		return (
			<Box className={`eds-grid eds-grid--m-cols-${columns} eds-grid--items-center`}>
				{filteredCapabilities.map(({ name, capabilityId, thumbnail }) => (
					<CustomCard
						objectFit="contain"
						textColor="black"
						classNames={cardHeight}
						key={capabilityId}
						message={name}
						altMessage={thumbnail?.alt}
						imgPath={thumbnail?.imgLink}
						onClick={handleNavigate(capabilityId)}
					/>
				))}
			</Box>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchFilter, sortDirection, sortField, capabilities, isLoading, t]);

	return (
		<div data-testid="capabilities-page">
			{isLoading && <Loader type="in-container" />}
			<div className="eds-spacing--px-56 eds-spacing--pt-40">
				<Text className="eds-spacing--mb-16" font="title-4">
					{t('pages.capabilities.header_label')}
				</Text>
				{content}
			</div>
		</div>
	);
};
