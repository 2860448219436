import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { translate } from 'config/localization/i18n';
import store from 'config/redux/store';
import { errorMessages, ErrorType, ResponseError } from 'services/types/Api';

const http = axios.create({
	withCredentials: true,
});

export const checkStatus = (response: AxiosResponse) => {
	switch (response.status) {
		case 400:
			return { errorType: ErrorType.BAD_REQUEST };
		case 401:
			return { errorType: ErrorType.UNAUTHORIZED };
		case 403:
			return { errorType: ErrorType.FORBIDDEN };
		case 404:
			return { errorType: ErrorType.NOT_FOUND };
		case 500:
			return { errorType: ErrorType.INTERNAL_SERVER_ERROR };
		case 503:
			return { errorType: ErrorType.SERVICE_UNAVAILABLE };
		default:
			return response;
	}
};

export const setAuthHeaderInterceptor = (config: InternalAxiosRequestConfig) => {
	const {
		auth: { accessToken },
	} = store.getState();
	config.headers['Authorization'] = `Bearer ${accessToken}`;

	return config;
};

export const setResponseInterceptor = (response: AxiosResponse) => {
	return response;
};

export const setResponseErrorInterceptor = (error: AxiosError<ResponseError>) => {
	const errorTemplate = {
		errorType: ErrorType.BAD_REQUEST,
		errorMessage: translate('error_messages.default_error'),
	};
	if (error.response) {
		const { errorType } = checkStatus(error.response) as Partial<ResponseError>;
		const errorMessage =
			errorType && errorType in errorMessages ? errorMessages[errorType] : errorTemplate.errorMessage;

		return Promise.reject({ errorType: errorType ? errorType : errorTemplate.errorType, errorMessage });
	}

	return Promise.reject(errorTemplate);
};

http.interceptors.request.use(setAuthHeaderInterceptor);
http.interceptors.response.use(setResponseInterceptor, setResponseErrorInterceptor);

export default http;
