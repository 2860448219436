import type { IDToken } from '@okta/okta-auth-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserState {
	name: string;
	email: string;
	initials: string;
}

export const initialState: UserState = {
	name: '',
	email: '',
	initials: '',
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser(state: UserState, { payload }: PayloadAction<IDToken['claims'] | null>) {
			state.name = payload?.name ?? '';
			state.email = payload?.email ?? '';
			state.initials = state.name
				.split(' ')
				.map((n) => n[0])
				.join('');
		},
		clearUser() {
			return initialState;
		},
	},
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
