import { Box, Button } from '@nike/eds';
import { ReactNode } from 'react';

interface StepResultWrapperProps {
	content: ReactNode;
	submitButtonLable: string;
	onSubmit: () => void;
}

export const StepResultWrapper = ({ content, submitButtonLable, onSubmit }: StepResultWrapperProps) => {
	return (
		<Box className="eds-spacing--py-24 eds-spacing--px-32">
			{content}
			<div className="eds-flex eds-flex--align-items-center eds-flex--direction-row-reverse eds-spacing--pt-24">
				<Button type="button" size="small" onClick={onSubmit}>
					{submitButtonLable}
				</Button>
			</div>
		</Box>
	);
};
