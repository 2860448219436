import { translate } from 'config/localization/i18n';

export interface ResponseError extends Error {
	response?: Response;
	meta?: Record<string, unknown>;
	errorType: ErrorType;
	errorMessage: string;
}

export enum ErrorType {
	BAD_REQUEST = 'Bad Request',
	UNAUTHORIZED = 'Unauthorized',
	FORBIDDEN = 'Forbidden',
	NOT_FOUND = 'Not Found',
	INTERNAL_SERVER_ERROR = 'Internal Server Error',
	SERVICE_UNAVAILABLE = 'Service Unavailable',
}

export const errorMessages = {
	[ErrorType.BAD_REQUEST]: translate('error_messages.bad_request'),
	[ErrorType.UNAUTHORIZED]: translate('error_messages.unauthorized'),
	[ErrorType.FORBIDDEN]: translate('error_messages.forbidden'),
	[ErrorType.NOT_FOUND]: translate('error_messages.not_found'),
	[ErrorType.INTERNAL_SERVER_ERROR]: translate('error_messages.internal_server_error'),
	[ErrorType.SERVICE_UNAVAILABLE]: translate('error_messages.service_unvailable'),
};
