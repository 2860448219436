import { createAsyncThunk } from '@reduxjs/toolkit';

import { http } from 'config/axios';
import { getCredentialsUrl, getIntegrationsUrl, getPartnersUrl } from 'services/constants/api';

export const ACTIVE_KEY = 'active_only';
export const SORT_KEY = 'sort';
export const FILTER_KEY = 'filter';
export const DEFAULT_SORT_VALUE = 'createdDateDesc';
export const getOwnerFilter = (email: string) => `business_owner_emails(${email})`;

export const getPartners = createAsyncThunk('[Roster] Get list of partners', async (_, { rejectWithValue }) => {
	try {
		const params = new URLSearchParams();
		params.append(ACTIVE_KEY, 'false');
		params.append(SORT_KEY, DEFAULT_SORT_VALUE);

		const url = getPartnersUrl();

		const { data } = await http.get(url, { params });
		return data;
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const getIntegrations = createAsyncThunk(
	'[Roster] Get list of integrations',
	async ({ email }: { email: string }, { rejectWithValue }) => {
		try {
			const params = new URLSearchParams();
			params.append(SORT_KEY, DEFAULT_SORT_VALUE);
			params.append(FILTER_KEY, getOwnerFilter(email));

			const url = getIntegrationsUrl();

			const { data } = await http.get(url, { params });
			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const getCredentials = createAsyncThunk(
	'[Roster] Get list of credentials',
	async ({ email }: { email: string }, { rejectWithValue }) => {
		try {
			const params = new URLSearchParams();
			params.append(SORT_KEY, DEFAULT_SORT_VALUE);
			params.append(FILTER_KEY, getOwnerFilter(email));

			const url = getCredentialsUrl();

			const { data } = await http.get(url, { params });
			return data;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);
