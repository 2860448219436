import { Box, Card, Text } from '@nike/eds';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { ImageViewer } from 'components/common/ImageViewer';
import { Loader } from 'components/common/Loader';
import { NavigationTrail } from 'components/common/NavigationTrail';
import { useDispatch, useSelector } from 'config/redux/store';
import { IntegrationPatternsView } from 'pages/CapabilityDetailsPage/CapabilityResources';
import { getCapability } from 'services/api/mos-configurator/MosConfiguratorApi';
import { CAPABILITIES_ROUTE } from 'services/constants/routes';
import {
	selectCapability,
	selectCapabilityError,
	selectCapabilityIntegrationPatterns,
	selectIsLoadingCapability,
} from 'services/store/capability/CapabilitySelectors';
import { clearCapabilityError, resetCapabilityState } from 'services/store/capability/CapabilitySlice';
import { useSnack } from 'utils/hooks/snack';

export const CapabilityDetailsPage = () => {
	const snack = useSnack();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { capabilityId = '' } = useParams();

	const error = useSelector(selectCapabilityError);
	const capability = useSelector(selectCapability);
	const integrationPatterns = useSelector(selectCapabilityIntegrationPatterns);
	const isLoading = useSelector(selectIsLoadingCapability);

	useEffect(() => {
		if (capabilityId && capability.capabilityId !== capabilityId) {
			dispatch(resetCapabilityState());
			dispatch(getCapability({ capabilityId }));
		}
	}, [capability.capabilityId, capabilityId, dispatch]);

	useEffect(() => {
		if (error) {
			snack.error(error);
			dispatch(clearCapabilityError());
		}
	}, [error, snack, dispatch]);

	const breadcrumbs = [
		{
			label: t('pages.capabilities.header_label'),
			url: CAPABILITIES_ROUTE,
			isLoading: false,
		},
	];

	return (
		<div data-testid="capability-details-page">
			{isLoading && <Loader type="in-container" />}
			<div className="eds-spacing--px-56 eds-spacing--pt-40">
				<NavigationTrail
					classNames="eds-spacing--mb-32"
					isLoading={isLoading}
					breadcrumbs={breadcrumbs}
					currentPage={t('pages.capability_details.current_page_label', { capability_name: capability.name })}
				/>
				{!isLoading && (
					<>
						<Box className="eds-flex eds-flex--align-items-center eds-spacing--mb-16">
							<Text className="eds-flex eds-flex--align-items-center" font="title-4">
								{t('pages.capability_details.header_label')}
							</Text>
						</Box>
						<Card className="eds-spacing--mb-16" style={{ overflowX: 'hidden' }}>
							<Box
								className={
									capability.description
										? 'eds-spacing--mb-32'
										: 'eds-flex eds-flex--align-items-center eds-flex--justify-content-center'
								}
							>
								{capability.description ? (
									<Text font="body-2">{capability.description}</Text>
								) : (
									<Text font="title-5">{t('pages.capability_details.no_description_message')}</Text>
								)}
							</Box>
							<Box className="eds-grid eds-grid--m-cols-1 eds-grid--items-center eds-spacing--mb-16">
								{capability?.visualDescription?.map(({ imgLink, alt }) => (
									<ImageViewer classNames="eds-spacing-mb-24" key={uuidv4()} imgPath={imgLink} altMessage={alt} />
								))}
							</Box>
						</Card>
						<IntegrationPatternsView capability={capability} integrationPatterns={integrationPatterns} />
					</>
				)}
			</div>
		</div>
	);
};
