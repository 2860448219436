import { Box, Button, Card, Icon, Text } from '@nike/eds';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { CustomCard } from 'components/common/CustomCard';
import { ImageViewer } from 'components/common/ImageViewer';
import { Loader } from 'components/common/Loader';
import { useDispatch, useSelector } from 'config/redux/store';
import { IntegrationTypesView } from 'pages/CapabilityDetailsPage/CapabilityResources';
import { getIntegrationPattern } from 'services/api/mos-configurator/MosConfiguratorApi';
import { getIntegrationPatternConfigurationRoute } from 'services/constants/routes';
import {
	selectIntegrationPattern,
	selectIntegrationPatternError,
	selectIsLoadingIntegrationPattern,
} from 'services/store/integrationPattern/IntegrationPatternSelectors';
import {
	clearIntegrationPatternError,
	resetIntegrationPatternState,
} from 'services/store/integrationPattern/IntegrationPatternSlice';
import { CapabilityDetails, CapabilityIntegrationPattern } from 'services/types/Capability';
import { CapabilityResourcesMode } from 'services/types/common';
import { useSnack } from 'utils/hooks/snack';

interface IntegrationPatternsViewProps {
	capability: CapabilityDetails;
	integrationPatterns: CapabilityIntegrationPattern[];
}

export const IntegrationPatternsView = ({ capability, integrationPatterns }: IntegrationPatternsViewProps) => {
	const snack = useSnack();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [openedIntegrationPatternId, setOpenedIntegrationPatternId] = useState('');
	const [integrationPatternsViewMode, setIntegrationPatternsViewMode] = useState<CapabilityResourcesMode>(
		CapabilityResourcesMode.LIST
	);

	const error = useSelector(selectIntegrationPatternError);
	const integrationPattern = useSelector(selectIntegrationPattern);
	const isLoading = useSelector(selectIsLoadingIntegrationPattern);

	useEffect(() => {
		if (openedIntegrationPatternId && integrationPattern?.integrationPatternId !== openedIntegrationPatternId) {
			dispatch(getIntegrationPattern({ integrationPatternId: openedIntegrationPatternId }));
		}
	}, [integrationPattern.integrationPatternId, openedIntegrationPatternId, dispatch]);

	useEffect(() => {
		if (error) {
			snack.error(error);
			dispatch(clearIntegrationPatternError());
		}
	}, [error, snack, dispatch]);

	const handleUseIntegrationPattern = useCallback(
		() =>
			navigate(getIntegrationPatternConfigurationRoute(capability?.capabilityId as string, openedIntegrationPatternId)),
		[capability?.capabilityId, openedIntegrationPatternId, navigate]
	);

	const handleShowIntegrationPatternDetails = useCallback(
		(integrationPatternId: string) => () => {
			setOpenedIntegrationPatternId(integrationPatternId);
			setIntegrationPatternsViewMode(CapabilityResourcesMode.DETAILS);
		},
		[]
	);

	const handleShowIntegrationPatterns = () => {
		setOpenedIntegrationPatternId('');
		setIntegrationPatternsViewMode(CapabilityResourcesMode.LIST);
		dispatch(resetIntegrationPatternState());
	};

	const integrationPatternContent = useMemo(() => {
		if (!integrationPatterns?.length) {
			return (
				<Box className="eds-flex eds-flex--align-items-center eds-flex--justify-content-center">
					<Text font="title-3">
						{t('pages.capability_details.no_description_message', {
							capability_name: capability.name,
						})}
					</Text>
				</Box>
			);
		}

		return (
			<Box className="eds-grid eds-grid--m-cols-4 eds-grid--items-center">
				{integrationPatterns?.map(({ name, integrationPatternId, thumbnail }) => (
					<CustomCard
						objectFit="contain"
						textColor="black"
						classNames="h200"
						key={integrationPatternId}
						message={name}
						altMessage={thumbnail?.alt}
						imgPath={thumbnail?.imgLink}
						onClick={handleShowIntegrationPatternDetails(integrationPatternId)}
					/>
				))}
			</Box>
		);
	}, [capability.name, integrationPatterns, t, handleShowIntegrationPatternDetails]);

	return (
		<div className="eds-spacing--mb-32">
			{isLoading && <Loader type="in-container" height="250px" />}

			{integrationPatternsViewMode === CapabilityResourcesMode.LIST && (
				<Box>
					<Text className="eds-flex eds-flex--align-items-center eds-spacing--mb-16" font="title-4">
						{t('pages.capability_details.integration_patterns.integration_patterns_label')}
					</Text>
					{integrationPatternContent}
				</Box>
			)}

			{integrationPatternsViewMode === CapabilityResourcesMode.DETAILS && (
				<Box>
					<Box className="eds-spacing--mb-16">
						<Box
							className={`eds-flex eds-flex--align-items-center eds-spacing--mb-16 ${
								isLoading ? '' : 'eds-flex--justify-content-space-between'
							}`}
						>
							<Button type="button" variant="ghost" onClick={handleShowIntegrationPatterns}>
								<Icon name="ArrowLeft" />
								{t('pages.capability_details.integration_patterns.back_btn_label')}
							</Button>
							{!isLoading && (
								<Button type="button" className="capitalize" onClick={handleUseIntegrationPattern}>
									{t('pages.capability_details.integration_patterns.use_integration_pattern_btn_label', {
										integration_pattern_name: integrationPattern?.name,
									})}
								</Button>
							)}
						</Box>
						{!isLoading && (
							<>
								<Box className="eds-flex eds-flex--align-items-center eds-spacing--mb-16">
									<Text font="title-4" className="capitalize">
										{t('pages.capability_details.integration_patterns.header_label', {
											integration_pattern_name: integrationPattern?.name,
										})}
									</Text>
								</Box>
								<Card style={{ overflowX: 'hidden' }}>
									<Box
										className={
											integrationPattern.description
												? 'eds-spacing--mb-32'
												: 'eds-flex eds-flex--align-items-center eds-flex--justify-content-center'
										}
									>
										{integrationPattern.description ? (
											<Text font="body-2">{integrationPattern.description}</Text>
										) : (
											<Text font="title-5">{t('pages.capability_details.no_description_message')}</Text>
										)}
									</Box>
									{!!integrationPattern?.examples?.length && (
										<Box className="eds-spacing--mb-32">
											<Text className="eds-spacing--mb-16" font="title-4">
												{t('pages.capability_details.integration_patterns.examples_label')}
											</Text>
											{integrationPattern.examples.map((example) => (
												<Box className="eds-spacing--mb-16" key={uuidv4()}>
													<Text className="eds-spacing--mb-8" font="subtitle-1">
														{example.header}
													</Text>
													<Text font="body-2">{example.text}</Text>
												</Box>
											))}
										</Box>
									)}
									<Box className="eds-spacing--mb-32">
										<Text className="eds-spacing--mb-16" font="title-4">
											{t('pages.capability_details.integration_patterns.estimates_label')}
										</Text>
										<Text font="body-2">
											{integrationPattern?.estimates ? integrationPattern.estimates : t('common.no_provided_message')}
										</Text>
									</Box>
									<Box className="eds-grid eds-grid--m-cols-1 eds-grid--items-center">
										{integrationPattern?.visualDescription.map(({ imgLink, alt }) => (
											<ImageViewer classNames="eds-spacing-mb-24" key={uuidv4()} imgPath={imgLink} altMessage={alt} />
										))}
									</Box>
								</Card>
							</>
						)}
					</Box>
					{!isLoading && <IntegrationTypesView integrationPattern={integrationPattern} />}
					{!isLoading && (
						<Box className="eds-flex eds-flex--direction-row-reverse">
							<Button type="button" className="capitalize" onClick={handleUseIntegrationPattern}>
								{t('pages.capability_details.integration_patterns.use_integration_pattern_btn_label', {
									integration_pattern_name: integrationPattern?.name,
								})}
							</Button>
						</Box>
					)}
				</Box>
			)}
		</div>
	);
};
