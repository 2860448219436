import { Box, Button, Card, Icon, Text } from '@nike/eds';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomCard } from 'components/common/CustomCard';
import { useSelector } from 'config/redux/store';
import { selectIntegrationTypes } from 'services/store/integrationPattern/IntegrationPatternSelectors';
import { CapabilityResourcesMode } from 'services/types/common';
import { IntegrationPatternDetails, IntegrationType } from 'services/types/IntegrationPattern';

interface IntegrationTypesViewProps {
	integrationPattern: IntegrationPatternDetails;
}

export const IntegrationTypesView = ({ integrationPattern }: IntegrationTypesViewProps) => {
	const { t } = useTranslation();

	const [openedIntegrationType, setOpenedIntegrationType] = useState<IntegrationType | null>(null);
	const [integrationTypesViewMode, setIntegrationTypesViewMode] = useState<CapabilityResourcesMode>(
		CapabilityResourcesMode.LIST
	);

	const integrationTypes = useSelector(selectIntegrationTypes);

	const handleShowIntegrationTypeDetails = useCallback(
		(integrationType: IntegrationType) => () => {
			setOpenedIntegrationType(integrationType);
			setIntegrationTypesViewMode(CapabilityResourcesMode.DETAILS);
		},
		[]
	);

	const handleShowIntegrationTypes = () => {
		setOpenedIntegrationType(null);
		setIntegrationTypesViewMode(CapabilityResourcesMode.LIST);
	};

	const integrationTypesContent = useMemo(() => {
		if (!integrationTypes?.length) {
			return (
				<Card>
					<Box className="eds-flex eds-flex--align-items-center eds-flex--justify-content-center">
						<Text font="title-5" className="eds-spacing--mb-8">
							{t('pages.capability_details.integration_types.no_items_found_message', {
								inegration_pattern_name: integrationPattern.name,
							})}
						</Text>
					</Box>
				</Card>
			);
		}

		return (
			<Box className="eds-grid eds-grid--m-cols-4 eds-grid--items-center">
				{integrationTypes?.map((integrationType) => (
					<CustomCard
						objectFit="contain"
						textColor="black"
						classNames="h200"
						key={integrationType.name}
						message={integrationType.name}
						altMessage={integrationType.thumbnail?.alt}
						imgPath={integrationType.thumbnail?.imgLink}
						onClick={handleShowIntegrationTypeDetails(integrationType)}
					/>
				))}
			</Box>
		);
	}, [integrationTypes, t, integrationPattern.name, handleShowIntegrationTypeDetails]);

	return (
		<Box className="eds-spacing--mb-16">
			{integrationTypesViewMode === CapabilityResourcesMode.LIST && (
				<Box>
					<Text className="eds-flex eds-flex--align-items-center eds-spacing--mb-16 capitalize" font="title-4">
						{t('pages.capability_details.integration_types.integration_types_label', {
							integration_pattern_name: integrationPattern.name,
						})}
					</Text>
					{integrationTypesContent}
				</Box>
			)}

			{integrationTypesViewMode === CapabilityResourcesMode.DETAILS && (
				<Box style={{ minHeight: '245px' }}>
					<Box className="eds-flex eds-flex--align-items-center eds-spacing--mb-16">
						<Button type="button" variant="ghost" onClick={handleShowIntegrationTypes}>
							<Icon name="ArrowLeft" />
							{t('pages.capability_details.integration_types.back_btn_label')}
						</Button>
					</Box>
					<Box className="eds-flex eds-flex--align-items-center eds-spacing--mb-16">
						<Text font="title-4" className="capitalize">
							{t('pages.capability_details.integration_types.header_label', {
								integration_type_name: openedIntegrationType?.name,
							})}
						</Text>
					</Box>
					<Card style={{ overflowX: 'hidden' }}>
						<Box
							className={
								openedIntegrationType?.description
									? 'eds-spacing--mb-8'
									: 'eds-flex eds-flex--align-items-center eds-flex--justify-content-center'
							}
						>
							{openedIntegrationType?.description ? (
								<Text font="body-2">{openedIntegrationType?.description}</Text>
							) : (
								<Text font="title-5">{t('pages.capability_details.no_description_message')}</Text>
							)}
						</Box>
					</Card>
				</Box>
			)}
		</Box>
	);
};
