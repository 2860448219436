import { AegisClient, oAuthEvents } from '@nike/aegis-auth';
import type { AccessToken, IDToken } from '@okta/okta-auth-js';
import { useEffect } from 'react';

import { useDispatch, useSelector } from 'config/redux/store';
import { selectAuthState } from 'services/store/auth/AuthSelectors';
import { clearLogin, setAccessToken, setAuthLoading, setIdToken, setLoginError } from 'services/store/auth/AuthSlice';
import { clearUser } from 'services/store/user/UserSlice';

export enum LoginEvents {
	LOGIN_SUCCESS = 'login:success',
	LOGIN_EXPIRED = 'login:expired',
	LOGIN_ERROR = 'login:error',
	LOGOUT_SUCCESS = 'logout:success',
	RENEW_ACCESS_TOKEN_SUCCESS = 'renew:access:success',
	RENEW_ID_TOKEN_SUCCESS = 'renew:id:success',
	RENEW_ERROR = 'renew:error',
}

export const useAuthEvent = <Event extends keyof oAuthEvents>(
	client: AegisClient,
	event: Event,
	handler: oAuthEvents[Event]
): void => {
	useEffect(() => {
		if (!client) return;
		client.on(event, handler);

		return () => client.off(event, handler);
	}, [client, event, handler]);
};

export const useAuth = (client: AegisClient) => {
	const dispatch = useDispatch();
	const auth = useSelector(selectAuthState);

	const handleLogin = ({ accessToken, idToken }: { accessToken: AccessToken; idToken: IDToken }) => {
		dispatch(setAccessToken(accessToken));
		dispatch(setIdToken(idToken));
	};

	const handleRenewAccessToken = (accessToken: AccessToken) => {
		dispatch(setAccessToken(accessToken));
		dispatch(setAuthLoading(false));
	};

	const handleRenewIdToken = (idToken: IDToken) => {
		dispatch(setIdToken(idToken));
		dispatch(setAuthLoading(false));
	};

	const handleExpired = () => {
		client.logout();
	};

	const handleLogout = () => {
		dispatch(clearUser());
		dispatch(clearLogin());
	};

	const handleError = (error: Error) => {
		dispatch(setLoginError(JSON.stringify(error)));
	};

	useAuthEvent(client, LoginEvents.LOGIN_SUCCESS, handleLogin);
	useAuthEvent(client, LoginEvents.LOGIN_EXPIRED, handleExpired);
	useAuthEvent(client, LoginEvents.RENEW_ACCESS_TOKEN_SUCCESS, handleRenewAccessToken);
	useAuthEvent(client, LoginEvents.RENEW_ID_TOKEN_SUCCESS, handleRenewIdToken);
	useAuthEvent(client, LoginEvents.LOGIN_ERROR, handleError);
	useAuthEvent(client, LoginEvents.RENEW_ERROR, handleError);
	useAuthEvent(client, LoginEvents.LOGOUT_SUCCESS, handleLogout);

	useEffect(() => {
		if (!auth.hasInitStarted && !auth.isLoggedOut) {
			client.init({ originalUri: window.location.href });
		}
	}, [auth.hasInitStarted, auth.isLoggedOut, client]);

	return auth;
};
