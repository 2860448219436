import { NavEvent, setActiveValueInNavItems } from '@nike/eds';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { LeftSidebar } from 'components/common/LeftSidebar';
import { getVerticalNavItems } from 'components/Layouts/InternalLayout/InternalLayout.utils';

import styles from './InternalLayout.module.scss';

interface InternalLayoutProps {
	children: ReactNode;
}

export const InternalLayout = ({ children }: InternalLayoutProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const location = useLocation();
	const [navItems, setNavItems] = useState(getVerticalNavItems(location.pathname));

	const handleVerticalNavItemClick = (event: NavEvent) => {
		const items = setActiveValueInNavItems(event.id, navItems) ?? [];

		setNavItems(items);
		navigate(event.id);
	};

	return (
		<>
			<LeftSidebar
				appName={t('common.app_name')}
				items={navItems.map((item) => ({ ...item, label: t(item.label) }))}
				onNavigate={handleVerticalNavItemClick}
			/>
			<div data-testid="internal-layout" className={styles['container']}>
				<main data-testid="main" className={styles['container-content']}>
					{children}
				</main>
			</div>
		</>
	);
};
