import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { translate } from 'config/localization/i18n';
import { YYYY_MM_DD } from 'services/constants/common';
import {
	Field,
	FieldType,
	InputType,
	RelatedField,
	SelectItem,
	Step,
	STEP_MODES,
	ValueType,
} from 'services/types/common';
import { Property, Schema } from 'services/types/IntegrationPattern';

const DEFAULT_COUNT_OF_HASH_NUMBERS = 8;
export const ROOT_STEP = 'root';

export const URL_REGEXP = /(\b(http|https):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

export const getLinkifiedText = (text: string) =>
	text.replace(URL_REGEXP, '<a href="$1" class="eds-link" target="_blank" rel="noopener noreferrer">$1</a>');

export const isLocal = () => process.env.REACT_APP_ENABLE_DEBUG === 'true';

export const formatDate = (date: number | string | Date, dateFormat: string) => moment(date).format(dateFormat);

export const getHash = (count = DEFAULT_COUNT_OF_HASH_NUMBERS): string => {
	const hash = uuidv4();

	return hash.substring(0, count);
};

export const truncateText = (text: string, length: number) =>
	text.length > length ? `${text.substring(0, length)}...` : text;

export const logError = (error: { message: string }, errorInfo: { componentStack: string }) => {
	console.log(error.message);
	if (isLocal()) {
		console.log(errorInfo.componentStack);
	}
};

export const getColumnsCount = (count: number): number => {
	switch (count) {
		case 1:
		case 2:
			return 2;
		case 3:
			return 3;
		default:
			return 4;
	}
};

export const addDaysToDate = (date: Date, days: number): Date => {
	const newDate = new Date(date);
	newDate.setDate(date.getDate() + days);

	return newDate;
};

export const getCardHeightClassName = (count: number): string => {
	switch (count) {
		case 1:
		case 2:
			return 'h400';
		case 3:
			return 'h300';
		default:
			return 'h200';
	}
};

export const getOktaScopes = () => {
	const scopes = process.env.REACT_APP_OKTA_SCOPES;
	if (!scopes) {
		return [];
	}

	return scopes.split(',').map((scope) => scope.trim());
};

export const generateLabelFromKey = (key: string) => {
	const words = key?.split(/(?=[A-Z])/);

	const label = words
		.map((word) => {
			if (word === word.toUpperCase()) {
				return word;
			} else {
				return word.charAt(0).toUpperCase() + word.slice(1);
			}
		})
		.join(' ');

	const normalizedLabel = label
		.replace(/([A-Z])\s+/g, '$1')
		.replace(/([A-Z])(?=[a-z])/g, '$1')
		.trim();

	return normalizedLabel;
};

export const getFieldValue = (field: Field): ValueType => {
	switch (field.type) {
		case FieldType.STRING:
			return field.value ? field.value?.toString()?.trim() : field.value;
		case FieldType.NUMBER:
			return Number(field.value);
		case FieldType.ARRAY:
			return (field.value as SelectItem[]).map(({ value }) => value);
		case FieldType.ENUM:
			return (field.value as SelectItem).value;
		case FieldType.DATE:
			return formatDate(field.value as string, YYYY_MM_DD);
		default:
			return field.value;
	}
};

export const getDefaultFieldValue = (field: Property): ValueType => {
	const type = field?.type;
	if (field?.const) {
		return field?.const;
	}

	switch (type) {
		case FieldType.ENUM:
			return field?.default || '';
		case FieldType.ARRAY:
			return Array.isArray(field?.default) ? field?.default : [];
		case FieldType.DATE:
			return field?.default ? formatDate(field?.default as string, YYYY_MM_DD) : '';
		case FieldType.STRING:
			return field?.default || '';
		case FieldType.NUMBER:
			return field?.default || 0;
		case FieldType.BOOLEAN:
			return field?.default || false;
		default:
			return field?.default || '';
	}
};

export const isRelated = (path: string, relatedFields: RelatedField[] | string[][] = []): boolean => {
	return relatedFields?.some((relatedField) => {
		if (Array.isArray(relatedField)) {
			return relatedField.includes(path);
		} else if (typeof relatedField === FieldType.OBJECT) {
			return (relatedField as { fields: string[]; value: string })?.fields?.includes(path);
		}
	});
};

export const isHiddenStep = (isCurrentStepHidden = false, newField: Field, step: Step): boolean => {
	const { path, dependentRequired } = step;
	const { relatedFields } = newField;
	if (isRelated(path, relatedFields)) {
		const newFieldValue = getFieldValue(newField);
		const newFieldPath = newField.path;

		return (
			dependentRequired?.reduce((shouldBeHidden, dependentRequiredField) => {
				if (!shouldBeHidden) {
					return shouldBeHidden;
				}

				if (dependentRequiredField.path === newFieldPath && dependentRequiredField.value === newFieldValue) {
					return false;
				}

				return shouldBeHidden;
			}, true) || false
		);
	}

	return isCurrentStepHidden;
};

export const getUpdatedFieldsWithValue = (fields: Field[], newField: Field) => {
	const { relatedFields } = newField;
	return fields.map((field) => {
		if (isRelated(field.path, relatedFields)) {
			const newFieldValue = getFieldValue(newField);
			const [toHide, toShow] = relatedFields?.reduce(
				([hide, show]: string[][], relatedField) => {
					if (typeof relatedField === FieldType.OBJECT) {
						const { fields: values, value } = relatedField as { fields: string[]; value: string };
						if (value === newFieldValue || value === undefined) {
							show.push(...values);
						} else {
							hide.push(...values);
						}
					}

					return [[...hide], [...show]];
				},
				[[], []]
			) || [[], []];

			if (toShow.includes(field.path)) {
				return {
					...field,
					isHidden: false,
					error: '',
				};
			}

			if (toHide.includes(field.path)) {
				return {
					...field,
					value: getDefaultFieldValue(field as Property),
					isHidden: true,
					error: '',
				};
			}
		}

		if (field?.path === newField?.path) {
			return {
				...field,
				...newField,
				dirty: true,
				error: '',
			};
		}

		return field;
	});
};

export const updateFormStepValueByPath = (steps: Step[], newField: Field): Step[] => {
	const updatedSteps = steps?.map((step) => {
		const { fields } = step;
		if (Array.isArray(fields) && fields.length) {
			const isHidden = isHiddenStep(step.isHidden, newField, step);

			if (isHidden) {
				return {
					...step,
					isHidden: true,
					mode: STEP_MODES.UNCOMPLETED,
					fields: fields.map((field) => ({
						...field,
						error: '',
						dirty: false,
						value: getDefaultFieldValue(field as Property),
					})),
				};
			}

			return {
				...step,
				isHidden: false,
				fields: getUpdatedFieldsWithValue(step.fields, newField),
			};
		}

		return step;
	});

	return updatedSteps;
};

export const isEmptyFormField = (field: Field) => {
	const { value, dirty = false, isHidden } = field;
	const isEmpty = value === '' || value === null || (Array.isArray(value) && value.length === 0);
	if (isHidden) {
		return false;
	}

	if (!dirty) {
		return value === 0 || isEmpty;
	}

	return isEmpty;
};

export const getFormStepFieldByPath = (steps: Step[], path = ''): Field | null => {
	const foundField = steps.reduce((found: Field | null, { fields = [] }) => {
		if (found) {
			return found;
		}

		const pathField = fields.find((field) => field.path === path);
		if (pathField) {
			return pathField;
		}

		return found;
	}, null);

	return foundField;
};

export const isValidFormStep = (currentStep: Step, steps: Step[]): boolean => {
	const { fields } = currentStep;

	return fields
		.map((field) => {
			const { isRequired, dependentRequired, validation, type } = field;
			const isEmptyValue = isEmptyFormField(field);
			if (isRequired && isEmptyValue) {
				return false;
			}

			if (Array.isArray(dependentRequired) && isEmptyValue) {
				return dependentRequired.some((required) => {
					const { path, value } = required;
					const dependentField = getFormStepFieldByPath(steps, path);

					if (dependentField) {
						if (value !== undefined) {
							switch (dependentField.type) {
								case FieldType.STRING:
								case FieldType.NUMBER:
									return dependentField.value !== value;
								case FieldType.BOOLEAN:
									return dependentField.value !== true;
								case FieldType.ARRAY:
								case FieldType.ENUM:
									return (dependentField.value as SelectItem).value !== value;
								default:
									return dependentField.value !== value;
							}
						}

						return dependentField.type === FieldType.BOOLEAN
							? dependentField.value !== true
							: isEmptyFormField(dependentField);
					}

					return false;
				});
			}

			if (validation && !isEmptyValue) {
				if (type === FieldType.STRING) {
					const { minLength, maxLength, pattern } = validation;
					const value = field.value as string;
					if (
						(minLength && value.length < minLength) ||
						(maxLength && value.length > maxLength) ||
						(pattern && !RegExp(pattern?.value).test(value))
					) {
						return false;
					}
				}

				if (type === FieldType.NUMBER) {
					const { minValue, maxValue } = validation;
					const value = parseInt(field.value as string, 10);
					if ((minValue && value < minValue) || (maxValue && value > maxValue)) {
						return false;
					}
				}
			}

			return true;
		})
		.every((item) => item === true);
};

export const updateStepFieldsWithError = (fields: Field[], steps: Step[]): Field[] =>
	fields.map((field) => {
		const { isRequired, dependentRequired, validation, type } = field;
		const isEmptyValue = isEmptyFormField(field);
		if (isRequired && isEmptyValue) {
			return {
				...field,
				error: translate('common.required_error_label'),
			};
		}

		if (Array.isArray(dependentRequired) && isEmptyValue) {
			const dependencies = dependentRequired.map((required) => {
				const { path, value } = required;
				const dependentField = getFormStepFieldByPath(steps, path);

				const dependency = {
					fieldPath: path,
					label: dependentField?.label,
					isRequired: false,
				};

				if (dependentField) {
					if (value !== undefined) {
						switch (dependentField.type) {
							case FieldType.STRING:
							case FieldType.NUMBER:
								return { ...dependency, isRequired: dependentField.value === value };
							case FieldType.BOOLEAN:
								return { ...dependency, isRequired: dependentField.value === true };
							case FieldType.ARRAY:
							case FieldType.ENUM:
								return { ...dependency, isRequired: (dependentField.value as SelectItem).value === value };
							default:
								return { ...dependency, isRequired: dependentField.value === value };
						}
					}

					return {
						...dependency,
						isRequired:
							dependentField.type === FieldType.BOOLEAN
								? dependentField.value === true
								: !isEmptyFormField(dependentField),
					};
				}

				return {
					...dependency,
					isRequired: false,
				};
			});

			if (dependencies.some((dependency) => dependency.isRequired)) {
				const dependentFields = dependencies
					.filter((dependency) => dependency.isRequired)
					.map(({ label }) => label)
					.join(', ');

				return {
					...field,
					error: `The field is required for ${dependentFields}`,
				};
			}
		}

		if (validation && !isEmptyValue) {
			if (type === FieldType.STRING) {
				const { minLength, maxLength, pattern } = validation;
				const value = field.value as string;
				if (minLength && value.length < minLength) {
					return {
						...field,
						error: translate('common.min_length_error_label', { min_length: minLength }),
					};
				}

				if (maxLength && value.length > maxLength) {
					return {
						...field,
						error: translate('common.max_length_error_label', { max_length: maxLength }),
					};
				}

				if (pattern && !RegExp(pattern?.value).test(value)) {
					return {
						...field,
						error: pattern?.message || translate('common.pattern_error_label', { pattern: pattern?.value }),
					};
				}
			}

			if (type === FieldType.NUMBER) {
				const { minValue, maxValue } = validation;
				const value = parseInt(field.value as string, 10);
				if (minValue && value < minValue) {
					return {
						...field,
						error: translate('common.min_value_error_label', { min_value: minValue }),
					};
				}

				if (maxValue && value > maxValue) {
					return {
						...field,
						error: translate('common.max_value_error_label', { max_value: maxValue }),
					};
				}
			}
		}

		return field;
	});

export const updateFormSpepWithError = (steps: Step[], stepIndex: number): Step[] => {
	return steps.map((step, index) => {
		if (index === stepIndex) {
			const { fields } = step;
			return {
				...step,
				mode: STEP_MODES.EDIT,
				fields: updateStepFieldsWithError(fields, steps),
			};
		}

		return {
			...step,
			mode: step.mode === STEP_MODES.EDIT ? STEP_MODES.PREVIEW : step.mode,
		};
	});
};

export const getTextFieldType = (type: string, isSecure = false): string => {
	if (isSecure) {
		return InputType.PASSWORD;
	}

	if (type === FieldType.DATE) {
		return InputType.DATE;
	}

	return InputType.TEXT;
};

export const getFormStepFieldByKey = (steps: Step[], key = ''): Field | null => {
	const foundField = steps.reduce((found: Field | null, { fields = [] }) => {
		if (found) {
			return found;
		}

		const keyField = fields.find((field) => field.key === key);
		if (keyField) {
			return keyField;
		}

		return found;
	}, null);

	return foundField;
};

export const transformPropertyToField = (
	property: Property,
	key: string,
	path: string,
	required: string[] = []
): Field => {
	const isRequired = required.includes(key);
	const label = property?.label || generateLabelFromKey(key);

	return {
		key,
		description: property?.description || '',
		path,
		isRequired,
		isHidden: !!property.const,
		isConst: !!property.const,
		error: '',
		value: getDefaultFieldValue(property),
		isSecure: property?.sensitive || false,
		isDisabled: false,
		default: property?.default || property?.const || null,
		type: property?.type,
		label,
		placeholder: property?.placeholder || '',
		options: property?.enum ? property.enum : [],
		validation: property?.validation,
	};
};

const DEFAULT_STEP = {
	title: 'General',
	error: '',
	formName: ROOT_STEP,
	path: ROOT_STEP,
	mode: STEP_MODES.EDIT,
	isHidden: false,
	isActive: false,
};

const transformSchemaToFields = (schema: Schema, parentPath: string, steps: Step[]): Field[] => {
	const fields: Field[] = [];

	for (const property in schema.properties) {
		const path = parentPath ? `${parentPath}.${property}` : property;
		const currentProperty = { ...schema.properties[property] };
		currentProperty.type = currentProperty?.enum ? FieldType.ENUM : currentProperty?.type || FieldType.STRING;

		if (currentProperty.type === FieldType.OBJECT && currentProperty.properties) {
			if (currentProperty.label) {
				const newStep = { ...DEFAULT_STEP, fields: [] };
				newStep.title = currentProperty.label || generateLabelFromKey(property);
				newStep.formName = property;
				newStep.path = path;
				newStep.mode = STEP_MODES.DISABLE;

				// eslint-disable-next-line @typescript-eslint/no-use-before-define
				const newSteps: Step[] = transformSchemaToSteps(currentProperty as Schema, path, newStep);
				steps.push(...newSteps);
			} else {
				fields.push(...transformSchemaToFields(currentProperty as Schema, path, steps));
			}
		} else {
			const field: Field = transformPropertyToField(
				currentProperty,
				property,
				path !== ROOT_STEP ? path : property,
				schema?.required
			);

			fields.push(field);
		}
	}

	return fields;
};

export const transformSchemaToSteps = (schema: Schema, parentPath = '', step: Step | null = null): Step[] => {
	const steps: Step[] = [];
	const required = schema?.required || [];
	const currentStep = step ? step : { ...DEFAULT_STEP, fields: [] };

	for (const property in schema.properties) {
		const path = parentPath === '' ? ROOT_STEP : `${parentPath}.${property}`;
		const currentProperty = { ...schema.properties[property] };
		currentProperty.type = currentProperty?.enum ? FieldType.ENUM : currentProperty?.type || FieldType.STRING;

		if (currentProperty.type === 'object') {
			if (currentProperty.label) {
				const newStep = { ...DEFAULT_STEP, fields: [] };
				newStep.title = currentProperty.label || generateLabelFromKey(property);
				newStep.formName = property;
				newStep.path = path !== ROOT_STEP ? path : property;
				newStep.mode = STEP_MODES.DISABLE;

				steps.push(...transformSchemaToSteps(currentProperty as Schema, newStep.path, newStep));
			} else {
				const fields = transformSchemaToFields(currentProperty as Schema, path !== ROOT_STEP ? path : property, steps);
				step?.fields?.push(...fields);
			}
		} else {
			currentStep.fields.push(
				transformPropertyToField(currentProperty, property, path !== ROOT_STEP ? path : property, required)
			);
		}
	}

	if (currentStep.fields.length) {
		steps.unshift(currentStep);
	}
	return steps;
};

export const getIntegrationName = (
	data: {
		integrationPatternName: string;
		capabilityName: string;
		partnerName: string;
	},
	hash: string
): string => {
	const { integrationPatternName, capabilityName, partnerName } = data;

	if (!partnerName) {
		return `${capabilityName} - ${integrationPatternName} (${hash})`;
	}

	return `${partnerName}: ${capabilityName} - ${integrationPatternName} (${hash})`;
};

export const getStepsPayload = (steps: Step[]): { [key: string]: ValueType | object } => {
	return steps
		.filter(({ isHidden }) => !isHidden)
		.map((step) => ({
			...step,
			fields: step?.fields?.filter(({ isConst, isHidden }) => !isHidden || (isHidden && isConst)),
		}))
		.reduce((payload: { [key: string]: ValueType | object }, step) => {
			const fields = step.fields;
			fields.forEach((field) => {
				const paths = field.path.split('.');
				let currentAcc = payload;
				for (let i = 0; i < paths.length; ++i) {
					const key = paths[i];
					if (!currentAcc[key]) {
						currentAcc[key] = i === paths.length - 1 ? getFieldValue(field) : {};
					}
					currentAcc = currentAcc[key] as { [key: string]: ValueType };
				}
			});

			return payload;
		}, {});
};

export const updateFieldsWithCommonDependency = (
	fields: Field[],
	dependencyPath: string,
	parentPath: string,
	dependency: string[]
): Field[] => {
	const modifyiedDependencyPath = parentPath === ROOT_STEP ? dependencyPath : `${parentPath}.${dependencyPath}`;

	return fields.map((field) => {
		if (field.path === modifyiedDependencyPath) {
			const relatedFields = dependency.map((path) => ({
				fields: parentPath === ROOT_STEP ? [path] : [`${parentPath}.${path}`],
				value: undefined,
			}));
			return {
				...field,
				relatedFields: field.relatedFields ? [...field.relatedFields, ...relatedFields] : [...relatedFields],
			} as Field;
		}

		if (dependency.includes(field.key)) {
			const dependentRequired = field.dependentRequired
				? [...field.dependentRequired, { path: modifyiedDependencyPath, value: undefined }]
				: [{ path: modifyiedDependencyPath, value: undefined }];

			return {
				...field,
				dependentRequired,
			} as Field;
		}

		return field;
	});
};

export const updateFieldsWithExtendedDependency = (
	fields: Field[],
	dependency: {
		[dependency: string]: string[];
	},
	dependencyPath: string,
	parentPath: string,
	dependentRequiredPathFields: string[]
): Field[] => {
	return fields.map((field) => {
		if (field.path === dependencyPath) {
			const relatedFields = Object.entries(dependency).map(([value, items]) => ({
				fields: Array.isArray(items) ? items.map((item) => `${parentPath}.${item}`) : items,
				value,
			}));

			return {
				...field,
				relatedFields: field.relatedFields ? [...field.relatedFields, ...relatedFields] : [...relatedFields],
			} as Field;
		}

		if (dependentRequiredPathFields.includes(field.path)) {
			const dependentRequired = Object.keys(dependency)
				.map((key) => ({
					fields: dependency[key],
					value: key,
				}))
				.filter((item) => (item?.fields as string[]).includes(field.key))
				.map((item) => ({ value: item.value, path: dependencyPath }));

			return {
				...field,
				isHidden: true,
				dependentRequired,
			};
		}

		return field;
	});
};

export const updateStepFieldsWithCommonDependency = (
	steps: Step[],
	dependencyPath: string,
	parrentPath: string,
	dependency: string[]
): Step[] => {
	return steps.map((step) => {
		const { fields } = step;

		return {
			...step,
			fields: updateFieldsWithCommonDependency(fields, dependencyPath, parrentPath, dependency),
		};
	});
};

export const updateStepsWithDependency = (
	steps: Step[],
	dependency: {
		[dependency: string]: string[];
	},
	dependencyPath: string,
	parentPath: string
): Step[] => {
	const dependentRequiredPathFields = [...new Set(Object.values(dependency).flat())].map(
		(key) => `${parentPath}.${key}`
	);

	return steps.map((step) => {
		const { fields, path } = step;

		if (fields.some((field) => field.path === dependencyPath)) {
			return {
				...step,
				fields: updateFieldsWithExtendedDependency(
					fields,
					dependency,
					dependencyPath,
					parentPath,
					dependentRequiredPathFields
				),
			};
		}

		if (dependentRequiredPathFields.includes(path)) {
			const dependentRequired = Object.keys(dependency).map((key) => ({
				path: dependencyPath,
				value: key,
			}));

			return {
				...step,
				isHidden: true,
				dependentRequired,
			};
		}

		return step;
	});
};

export const updateStepsWithDependentRequired = (steps: Step[], schema: Schema, parentPath = ROOT_STEP): Step[] => {
	let updatedSteps: Step[] = [...steps];

	if (schema?.dependentRequired) {
		for (const dependencyPath in schema?.dependentRequired) {
			const dependency = schema.dependentRequired[dependencyPath];

			if (Array.isArray(dependency)) {
				updatedSteps = updateStepFieldsWithCommonDependency(updatedSteps, dependencyPath, parentPath, dependency);
			} else if (typeof dependency === FieldType.OBJECT) {
				updatedSteps = updateStepsWithDependency(updatedSteps, dependency, dependencyPath, parentPath);
			}
		}
	}

	for (const property in schema?.properties) {
		const path = parentPath !== ROOT_STEP ? `${parentPath}.${property}` : ROOT_STEP;
		const currentProperty = schema.properties[property];

		if (currentProperty.type === FieldType.OBJECT) {
			updatedSteps = updateStepsWithDependentRequired(
				updatedSteps,
				currentProperty as Schema,
				path !== ROOT_STEP ? path : property
			);
		}
	}

	return updatedSteps;
};
