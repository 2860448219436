import { createContext, ReactNode } from 'react';

export interface SnackContextValue {
	success: (content: ReactNode | string) => void;
	warning: (content: ReactNode | string) => void;
	info: (content: ReactNode | string) => void;
	error: (content: ReactNode | string) => void;
	neutral: (content: ReactNode | string) => void;
	removeSnack: (snackId: string) => void;
}

export const getInitialSnackContextValues = () => ({
	success: () => null,
	warning: () => null,
	info: () => null,
	error: () => null,
	neutral: () => null,
	removeSnack: () => null,
});

export const SnackContext = createContext<SnackContextValue>(getInitialSnackContextValues());
