import { Box, Label, Text, Tooltip } from '@nike/eds';

import { translate } from 'config/localization/i18n';
import { Field, FieldType, SelectItem, Step } from 'services/types/common';
import { truncateText } from 'utils/utils';

export const VALUE_TEXT_LENGTH_LIMIT = 25;
export const DESCRIPTION_TEXT_LENGTH_LIMIT = 300;

import styles from './PreviewContent.module.scss';

export const getTextContent = (field: Field) => {
	const { value, isSecure } = field;
	const normalizedValue = value as string;
	if (normalizedValue.length > VALUE_TEXT_LENGTH_LIMIT) {
		return (
			<Tooltip bodySlot={<Text font="body-3">{normalizedValue}</Text>} placement="top">
				<>{truncateText(isSecure ? '*'.repeat(normalizedValue.length) : normalizedValue, VALUE_TEXT_LENGTH_LIMIT)}</>
			</Tooltip>
		);
	}

	return isSecure ? '*'.repeat(normalizedValue.length) : normalizedValue;
};

export const getArrayContent = (field: Field) => {
	const { value } = field;
	const items = (value as SelectItem[]).map(({ label }) => label).join(', ');
	if (items.length > VALUE_TEXT_LENGTH_LIMIT) {
		return (
			<Tooltip bodySlot={<Text font="body-3">{items}</Text>} placement="top">
				<>{truncateText(items, VALUE_TEXT_LENGTH_LIMIT)}</>
			</Tooltip>
		);
	}

	return items;
};

export const getFieldValue = (field: Field) => {
	const { type, value } = field;

	switch (type) {
		case FieldType.BOOLEAN:
			return value ? translate('common.yes_label') : translate('common.no_label');
		case FieldType.NUMBER:
			return value?.toString();
		case FieldType.STRING:
			return getTextContent(field);
		case FieldType.ARRAY:
			return getArrayContent(field);
		case FieldType.ENUM:
			return (value as SelectItem)?.label;
		default:
			return value as string;
	}
};

export const renderFieldValue = (field: Field) => {
	const { label = '', isHidden, description = '' } = field;

	if (isHidden) {
		return null;
	}

	const fieldValue = getFieldValue(field);

	const fieldDescription = truncateText(description, DESCRIPTION_TEXT_LENGTH_LIMIT);

	return (
		<Box key={field.path} className="eds-grid--xxl-col-2">
			<Box
				className={`eds-flex eds-flex--align-items-center eds-flex--justify-content-space-between ${styles['container']}`}
			>
				<Tooltip bodySlot={<Text font="body-3">{fieldDescription}</Text>} placement="top">
					<Label font="subtitle-2" color="grey-1">
						{label}
					</Label>
				</Tooltip>
				<Text font="subtitle-1" as="span">
					{fieldValue ? fieldValue : translate('common.not_provided_label')}
				</Text>
			</Box>
		</Box>
	);
};

export const renderStep = (step: Step) => {
	const { fields = [], title } = step;

	return (
		<Box key={step.formName} className="eds-spacing--mb-48">
			<Text className="eds-spacing--mb-16" font="title-5">
				{title}
			</Text>
			<Box className="eds-grid eds-grid--xxl-cols-4" style={{ rowGap: '8px', columnGap: '64px' }}>
				{fields.map(renderFieldValue)}
			</Box>
		</Box>
	);
};
