import { createSelector } from '@reduxjs/toolkit';

import { State } from 'config/redux/store';

export const selectPartners = (state: State) => state.roster.partners.items;
export const selectIsLoadingPartners = (state: State) => state.roster.partners.isLoading;
export const selectPartnersError = (state: State) => state.roster.partners.error;
export const selectIntegrations = (state: State) => state.roster.integrations.items;
export const selectIsLoadingIntegrations = (state: State) => state.roster.integrations.isLoading;
export const selectIntegrationsError = (state: State) => state.roster.integrations.error;
export const selectCredentials = (state: State) => state.roster.credentials.items;
export const selectIsLoadingCredentials = (state: State) => state.roster.credentials.isLoading;
export const selectCredentialsError = (state: State) => state.roster.credentials.error;

export const selectIsLoadingDashboardData = createSelector(
	[selectIsLoadingIntegrations, selectIsLoadingCredentials],
	(isLoadingIntegrations, isLoadingCredentials) => isLoadingIntegrations || isLoadingCredentials
);
