import { Snack, Snackbar, StatusIconProtoProps, Text } from '@nike/eds';
import { ReactNode } from 'react';

import { useSnack } from 'utils/hooks/snack';

interface SnackContainerProps {
	snacks: { id: string; content: ReactNode | string; status: StatusIconProtoProps['status'] }[];
}

export const DEFAULT_AUTO_DISMISS_TIME = 5000;

export const SnackContainer = ({ snacks }: SnackContainerProps) => {
	const snack = useSnack();

	const handleDismiss = (snackId: string) => snack.removeSnack(snackId);

	return (
		<Snackbar data-testid="snack-container">
			{snacks.map(({ id, status, content }) => (
				<Snack
					key={id}
					id={id}
					status={status}
					autoDismissDuration={DEFAULT_AUTO_DISMISS_TIME}
					onDismiss={handleDismiss}
				>
					{typeof content === 'string' ? <Text font="body-2">{content}</Text> : content}
				</Snack>
			))}
		</Snackbar>
	);
};
