import { Box, Button, Skeleton, Text } from '@nike/eds';
import { useNavigate } from 'react-router-dom';

import { Breadcrumb } from 'services/types/common';

interface NavigationTrailProps {
	classNames?: string;
	currentPage: string;
	isLoading?: boolean;
	breadcrumbs?: Breadcrumb[];
}

const SPACER = '/';

export const NavigationTrail = ({
	classNames,
	currentPage,
	breadcrumbs = [],
	isLoading = false,
}: NavigationTrailProps) => {
	const navigate = useNavigate();

	const handleNavigateBreadcrumb = (url: string) => navigate(url);

	const renderBreadcrumb = (breadcrumb: Breadcrumb, index: number, array: Breadcrumb[]) => {
		const { label, url, isLoading: isBreadcrumbLoading = false } = breadcrumb;

		const handleBreadcrumb = () => handleNavigateBreadcrumb(url);

		const isFirst = index === 0;

		const isLast = array.length - 1 === index;

		return (
			<Box className="eds-flex eds-flex--align-items-center" key={label + index}>
				{!isFirst && (
					<Text className="eds-spacing--mr-4 opacity-7" font="subtitle-1">
						{SPACER}
					</Text>
				)}
				{isBreadcrumbLoading ? (
					<Skeleton data-testid="breadcrumb-skeleton" height={20} width={80} />
				) : (
					<Button variant="ghost" type="button" onClick={handleBreadcrumb}>
						<Text className="eds-spacing--mr-4 opacity-7" font="subtitle-1">
							{label}
						</Text>
					</Button>
				)}
				{isLast && (
					<Text className="eds-spacing--mr-4 opacity-7" font="subtitle-1">
						{SPACER}
					</Text>
				)}
			</Box>
		);
	};

	return (
		<Box data-testid="navigation-trail" className={`eds-flex eds-flex--align-items-center ${classNames ?? ''}`}>
			{breadcrumbs?.map(renderBreadcrumb)}
			{isLoading ? (
				<Skeleton data-testid="current-page-skeleton" height={20} width={80} />
			) : (
				<Text font="subtitle-1">{currentPage}</Text>
			)}
		</Box>
	);
};
