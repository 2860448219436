import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCapabilities } from 'services/api/mos-configurator/MosConfiguratorApi';
import { ResponseError } from 'services/types/Api';
import { CapabilitiesApi, Capability } from 'services/types/Capability';
import { FilterOptions, SortDirection } from 'services/types/common';

export interface CapabilitiesState {
	isLoading: boolean;
	error: string;
	filterOptions: FilterOptions;
	capabilities: Capability[];
}

const DEFAULT_CAPABILITIES_SORT_FIELD = 'name';

export const initialState: CapabilitiesState = {
	isLoading: false,
	error: '',
	filterOptions: {
		searchFilter: '',
		sortDirection: SortDirection.ASC,
		sortField: DEFAULT_CAPABILITIES_SORT_FIELD,
	},
	capabilities: [],
};

const capabilitiesSlice = createSlice({
	name: 'capabilities',
	initialState,
	reducers: {
		setCapabilitiesSearchFilter(state: CapabilitiesState, { payload }: PayloadAction<string>) {
			state.filterOptions.searchFilter = payload;
		},
		setCapabilitiesSortDirection(state: CapabilitiesState, { payload }: PayloadAction<SortDirection>) {
			state.filterOptions.sortDirection = payload;
		},
		clearCapabilitiesError(state: CapabilitiesState) {
			state.error = '';
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getCapabilities.pending, (state: CapabilitiesState) => {
				state.isLoading = true;
			})
			.addCase(getCapabilities.fulfilled, (state: CapabilitiesState, { payload }: PayloadAction<CapabilitiesApi>) => {
				state.isLoading = false;
				state.capabilities = payload.objects;
			})
			.addCase(getCapabilities.rejected, (state: CapabilitiesState, { payload }) => {
				state.isLoading = false;
				state.error = (payload as ResponseError).errorMessage;
			});
	},
});

export const { setCapabilitiesSearchFilter, setCapabilitiesSortDirection, clearCapabilitiesError } =
	capabilitiesSlice.actions;

export default capabilitiesSlice.reducer;
