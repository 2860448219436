import { Step, STEP_MODES } from 'services/types/common';

export const isStepModeEdit = (mode: string) => mode === STEP_MODES.EDIT;
export const isStepModePreview = (mode: string) => mode === STEP_MODES.PREVIEW;
export const isStepModeDisable = (mode: string) => mode === STEP_MODES.DISABLE;
export const isStepModeUncompleted = (mode: string) => mode === STEP_MODES.UNCOMPLETED;

export const getStepsWithPreviewMode = (steps: Step[]): Step[] =>
	steps.map((step) => ({
		...step,
		mode: STEP_MODES.PREVIEW,
	}));

export const getStepsWithInitMode = (steps: Step[]): Step[] =>
	steps.map((step, idx) => ({
		...step,
		mode: idx === 0 ? STEP_MODES.EDIT : STEP_MODES.DISABLE,
	}));

export const formatInitSteps = ({ steps, isAllStepsFilled = false }: { steps: Step[]; isAllStepsFilled: boolean }) =>
	isAllStepsFilled ? getStepsWithPreviewMode(steps) : getStepsWithInitMode(steps);

export const getStepFormNames = (steps: Step[]) => steps.map(({ formName }: Step) => formName);
