import { ReactNode } from 'react';
import { animated } from 'react-spring';
import { UseMeasureRef } from 'react-use/lib/useMeasure';

import { StepHeader } from 'components/common/Stepper/Step/StepHeader';
import { STEP_MODES } from 'services/types/common';
import { useAnimatedStepHeight } from 'utils/hooks/animatedStepHeight';
import { useUpdateStepContentHeight } from 'utils/hooks/updateStepContentHeight';

import styles from './Step.module.scss';

interface StepProps {
	name: string;
	mode: STEP_MODES;
	stepIndex: number;
	children: ReactNode;
	onStepClick?: () => void;
}

export const Step = ({ name, mode, children, onStepClick }: StepProps) => {
	const [contentRef, contentHeight] = useUpdateStepContentHeight();
	const [animatedHeight] = useAnimatedStepHeight({ mode, contentHeight: contentHeight as number });

	return (
		<animated.div data-testid="stepper-step" className={styles['step']} style={{ height: animatedHeight }}>
			<div ref={contentRef as UseMeasureRef<HTMLDivElement>}>
				<StepHeader name={name} mode={mode} onStepClick={onStepClick} />
				{children}
			</div>
		</animated.div>
	);
};
