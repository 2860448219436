import { Suspense, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { App } from 'components/App';
import { Loader } from 'components/common/Loader';
import { ErrorFallback } from 'components/ErrorFallback';
import { LoginRequired } from 'components/LoginRequred';
import { SnackContextProvider } from 'components/SnackContextProvider';
import store, { persistor } from 'config/redux/store';
import { AppRoutes } from 'config/Routes';
import { getAuthClient } from 'utils/authClient';
import { logError } from 'utils/utils';
import 'config/localization/i18n';

export const AppContainer = () => {
	const { i18n } = useTranslation();
	const authClient = getAuthClient();

	useEffect(() => {
		const lng = navigator.language;
		i18n.changeLanguage(lng);
	}, [i18n]);

	return (
		<Provider store={store}>
			<Suspense fallback={<Loader type="full-screen" />}>
				<ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
					<PersistGate loading={<Loader type="full-screen" />} persistor={persistor}>
						<LoginRequired client={authClient}>
							<BrowserRouter>
								<SnackContextProvider>
									<App>
										<AppRoutes />
									</App>
								</SnackContextProvider>
							</BrowserRouter>
						</LoginRequired>
					</PersistGate>
				</ErrorBoundary>
			</Suspense>
		</Provider>
	);
};
