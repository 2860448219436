import type { AccessToken, APIError, IDToken } from '@okta/okta-auth-js';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ErrorType = null | Error | APIError | string;
export enum AuthType {
	INTERNAL = 'internal',
	EXTERNAL = 'external',
}

export interface AuthState {
	hasInitStarted: boolean;
	loginComplete: boolean;
	isLoading: boolean;
	isLoggedOut: boolean;
	accessToken: string | null;
	type: AuthType | null;
	idToken: string | null;
	tokenClaims: AccessToken['claims'] | null;
	idClaims: IDToken['claims'] | null;
	scopes: string[];
	loginError: ErrorType;
}

export const initialState: AuthState = {
	hasInitStarted: false,
	loginComplete: false,
	isLoading: false,
	isLoggedOut: false,
	accessToken: null,
	type: AuthType.INTERNAL,
	idToken: null,
	tokenClaims: null,
	idClaims: null,
	scopes: [],
	loginError: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAccessToken(state: AuthState, { payload }: PayloadAction<AccessToken>) {
			state.accessToken = payload?.accessToken ?? null;
			state.loginComplete = true;
			state.tokenClaims = payload?.claims ?? null;
			state.scopes = payload?.scopes ?? [];
			state.loginError = null;
			state.isLoading = false;
		},
		setAuthType(state: AuthState, { payload }: PayloadAction<AuthType>) {
			state.type = payload ?? null;
		},
		setIdToken(state: AuthState, { payload }: PayloadAction<IDToken>) {
			state.idToken = payload?.idToken ?? null;
			state.idClaims = payload?.claims ?? null;
		},
		setLoginError(state: AuthState, { payload }: PayloadAction<ErrorType>) {
			state.loginError = payload;
			state.isLoggedOut = true;
			state.hasInitStarted = false;
			state.accessToken = null;
			state.tokenClaims = null;
			state.scopes = [];
			state.isLoading = false;
		},
		clearLogin(state: AuthState) {
			state.loginError = null;
			state.isLoggedOut = true;
			state.hasInitStarted = false;
			state.accessToken = null;
			state.idToken = null;
			state.tokenClaims = null;
			state.idClaims = null;
			state.scopes = [];
			state.isLoading = false;
		},
		startInitAuth(state: AuthState) {
			state.isLoading = true;
			state.loginComplete = false;
			state.hasInitStarted = true;
			state.isLoggedOut = false;
		},
		resetInit(state: AuthState) {
			state.hasInitStarted = false;
		},
		setAuthLoading(state: AuthState, { payload }: PayloadAction<boolean>) {
			state.isLoading = payload;
		},
	},
});

export const { startInitAuth, clearLogin, setIdToken, setLoginError, setAccessToken, setAuthLoading, resetInit } =
	authSlice.actions;

export default authSlice.reducer;
