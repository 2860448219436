import { TextField } from '@nike/eds';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { InputType } from 'services/types/common';

export interface FormTextFieldProps {
	label: string;
	error: string;
	description: string;
	value: string | number;
	type?: string;
	disabled?: boolean;
	isRequired?: boolean;
	placeholder?: string;
	onChange: (value: string) => void;
}

export const FormTextField = ({
	value,
	error,
	label,
	description,
	disabled = false,
	placeholder,
	isRequired,
	type = InputType.TEXT,
	onChange,
}: FormTextFieldProps) => {
	const normalizedLabel = useMemo(() => {
		if (error) {
			return label;
		}

		return isRequired ? `${label} *` : label;
	}, [error, isRequired, label]);

	return (
		<TextField
			data-testid="text-field"
			data-text={description}
			id={uuidv4()}
			errorMessage={error}
			autoComplete={type === InputType.PASSWORD ? InputType.NEW_PASSWORD : 'off'}
			hasErrors={!!error}
			disabled={disabled}
			className={`${description ? '' : 'eds-spacing--mt-36'} ${error ? '' : 'eds-spacing--pb-24'}`}
			placeholder={placeholder}
			value={value}
			type={type}
			label={normalizedLabel}
			subtitle={description}
			onChange={(e) => onChange(e.target.value)}
		/>
	);
};
