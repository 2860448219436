import { createSelector } from '@reduxjs/toolkit';

import { State } from 'config/redux/store';
import { selectCapability, selectIsLoadingCapability } from 'services/store/capability/CapabilitySelectors';
import {
	selectIntegrationPattern,
	selectIsLoadingIntegrationPattern,
	selectIsLoadingIntegrationPatternSchema,
} from 'services/store/integrationPattern/IntegrationPatternSelectors';
import { PARTNER_NAME_KEY } from 'services/store/integrationPatternConfiguration/IntegrationPatternConfigurationSlice';
import { selectIsLoadingPartners } from 'services/store/roster/RosterSelectors';
import { selectUserEmail } from 'services/store/user/UserSelectors';
import { IntegrationPayload, PayloadType, RequestType, SelectItem, Step } from 'services/types/common';
import { getFormStepFieldByKey, getStepsPayload, isEmptyFormField, updateFormStepValueByPath } from 'utils/utils';

export const selectConfigurationFormSteps = (state: State) =>
	state.integrationPatternConfiguration.configurationFormSteps;
export const selectIntakeFormSteps = (state: State) => state.integrationPatternConfiguration.intakeFormSteps;
export const selectIsLoadingIntakeSchema = (state: State) =>
	state.integrationPatternConfiguration.intakeSchema.isLoading;
export const selectIntakeSchema = (state: State) => state.integrationPatternConfiguration.intakeSchema.schema;
export const selectIsIntegrationSubmitting = (state: State) => state.integrationPatternConfiguration.isLoading;
export const selectConfigurationSubmitError = (state: State) => state.integrationPatternConfiguration.error;

export const selectIsIntakeFormStepValid = (stepIdToValidate: number) =>
	createSelector([selectIntakeFormSteps], (intakeSteps) => {
		const stepToValidate = intakeSteps.find((_, index) => index == stepIdToValidate);
		if (stepToValidate) {
			const { fields } = stepToValidate;

			return fields.every((field) => {
				if (field?.isRequired) {
					const isEmptyValue = isEmptyFormField(field);
					return isEmptyValue ? false : true;
				}

				return true;
			});
		}

		return true;
	});

export const selectIntegrationName = createSelector([selectIntakeFormSteps], (steps: Step[]) => {
	return steps.reduce((name, { fields = [] }) => {
		if (name) {
			return name;
		}

		const nameField = fields.find((field) => field.key === 'name');
		if (nameField) {
			return nameField.value as string;
		}

		return name;
	}, '');
});

export const selectPartnerName = createSelector([selectIntakeFormSteps], (steps: Step[]) => {
	const partnerField = getFormStepFieldByKey(steps, PARTNER_NAME_KEY);

	return partnerField?.value as SelectItem;
});

export const selectParamsForNameIntegration = createSelector(
	[selectIntegrationPattern, selectCapability, selectIntakeFormSteps],
	(pattern, capability, intakeFormSteps) => {
		const partnerField = getFormStepFieldByKey(intakeFormSteps, PARTNER_NAME_KEY);

		return {
			integrationPatternName: pattern.name,
			capabilityName: capability.name,
			partnerName: (partnerField?.value as SelectItem)?.label ?? '',
		};
	}
);

export const selectIntegrationForm = createSelector(
	[selectIntegrationPattern, selectCapability, selectUserEmail, selectConfigurationFormSteps, selectIntakeFormSteps],
	(integrationPattern, capability, userEmail, configurationSteps, intakeSteps): IntegrationPayload => {
		const { integrationPatternId = '' } = integrationPattern;
		const { capabilityId = '' } = capability;

		const partnerNameField = getFormStepFieldByKey(intakeSteps, PARTNER_NAME_KEY);
		const updatedIntakeSteps = partnerNameField
			? updateFormStepValueByPath(intakeSteps, {
					...partnerNameField,
					value: { ...(partnerNameField.value as SelectItem), value: (partnerNameField.value as SelectItem)?.label },
			  })
			: intakeSteps;

		return {
			capabilityId,
			integrationPatternId,
			user: userEmail,
			requestType: RequestType.INTAKE,
			intakePayload: getStepsPayload(updatedIntakeSteps) as PayloadType,
			configurationPayload: getStepsPayload(configurationSteps) as PayloadType,
		};
	}
);

export const selectIsLoadingConfigurationPage = createSelector(
	[
		selectIsLoadingIntegrationPattern,
		selectIsLoadingIntegrationPatternSchema,
		selectIsLoadingIntakeSchema,
		selectIsLoadingCapability,
		selectIsLoadingPartners,
	],
	(
		isLoadingIntegrationPattern,
		isLoadingIntegrationPatternSchemas,
		isLoadingIntakeSchema,
		isLoadingCapability,
		isLoadingPartners
	) => {
		return (
			isLoadingIntegrationPattern ||
			isLoadingIntegrationPatternSchemas ||
			isLoadingIntakeSchema ||
			isLoadingCapability ||
			isLoadingPartners
		);
	}
);

export const selectConfigurationPageLoaders = createSelector(
	[selectIntegrationPattern, selectIsLoadingIntegrationPattern, selectIsLoadingCapability],
	(integrationPattern, isLoadingIntegrationPattern, isLoadingCapability) => {
		const { name } = integrationPattern;
		return {
			isLoadingIntegrationPattern: name ? false : isLoadingIntegrationPattern,
			isLoadingCapability,
		};
	}
);
