import { AegisClient } from '@nike/aegis-auth';

import { getOktaScopes } from './utils';

let authClient: AegisClient;

export const getAuthClient = () => {
	if (!authClient) {
		authClient = new AegisClient({
			qa: process.env.REACT_APP_IS_OKTA_QA === 'true',
			clientId: process.env.REACT_APP_OKTA_CLIENTID ?? '',
			redirectUri: window.location.origin,
			scopes: getOktaScopes(),
		});
	}

	return authClient;
};
