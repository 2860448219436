import { isStepModeDisable, isStepModeEdit, isStepModeUncompleted } from 'components/common/Stepper/Stepper.utils';
import { STEP_MODES } from 'services/types/common';

export const ARROW_COLOR_DISABLE = '#E5E5E5';
export const ARROW_COLOR_EDIT = '#707072';

export const ARROW_BACKGROUND_COLOR_DISABLE = '#FFF';
export const ARROW_BACKGROUND_COLOR_EDIT = '#FAFAFA';
export const ARROW_BACKGROUND_COLOR_PREVIEW = '#111111';
export const ARROW_BACKGROUND_COLOR_UNCOMPLETED = '#FAFAFA';

export const ARROW_BORDER_COLOR_DISABLE = '#E5E5E5';
export const ARROW_BORDER_COLOR_EDIT = '#707072';
export const ARROW_BORDER_COLOR_PREVIEW = '#111111';
export const ARROW_BORDER_COLOR_UNCOMPLETED = '#707072';

export const TITLE_COLOR_DISABLE = '#E5E5E5';
export const TITLE_COLOR_EDIT = '#111111';
export const TITLE_COLOR_PREVIEW = '#111111';
export const TITLE_COLOR_UNCOMPLETED = '#111111';

export const getTitleColor = (mode: STEP_MODES) => {
	if (isStepModeEdit(mode)) return TITLE_COLOR_EDIT;
	if (isStepModeDisable(mode)) return TITLE_COLOR_DISABLE;
	if (isStepModeUncompleted(mode)) return TITLE_COLOR_UNCOMPLETED;
	return TITLE_COLOR_PREVIEW;
};

export const getNumberColor = (mode: STEP_MODES) =>
	isStepModeEdit(mode) || isStepModeUncompleted(mode) ? ARROW_COLOR_EDIT : ARROW_COLOR_DISABLE;

export const getNumberBackgroundColor = (mode: STEP_MODES) => {
	if (isStepModeEdit(mode)) return ARROW_BACKGROUND_COLOR_EDIT;
	if (isStepModeDisable(mode)) return ARROW_BACKGROUND_COLOR_DISABLE;
	if (isStepModeUncompleted(mode)) return ARROW_BACKGROUND_COLOR_UNCOMPLETED;
	return ARROW_BACKGROUND_COLOR_PREVIEW;
};

export const getNumberBorderColor = (mode: STEP_MODES) => {
	if (isStepModeEdit(mode)) return ARROW_BORDER_COLOR_EDIT;
	if (isStepModeDisable(mode)) return ARROW_BORDER_COLOR_DISABLE;
	if (isStepModeUncompleted(mode)) return ARROW_BORDER_COLOR_UNCOMPLETED;
	return ARROW_BORDER_COLOR_PREVIEW;
};
