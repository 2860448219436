export const API_ROOT = '/api';
export const API_VERSION = '/v1';
export const PARTNERS_PATH = '/partners';
export const CREDENTIALS_PATH = '/credentials';
export const INTEGRATIONS_PATH = '/integrations';
export const CAPABILITIES_PATH = '/capabilities';
export const INTEGRATION_PATTERNS_PATH = '/integration_patterns';
export const INTEGRATION_TYPES_PATH = '/integration_types';
export const CONFIGURATION_PATH = '/configuration_requests';
export const INTAKE_FIELDS_PATH = '/intake_fields';

export const getCapabilitiesUrl = () =>
	`${process.env.REACT_APP_MOS_CONFIGURATOR_API}${CAPABILITIES_PATH}${API_VERSION}`;
export const getCapabilityUrl = (id: string) =>
	`${process.env.REACT_APP_MOS_CONFIGURATOR_API}${CAPABILITIES_PATH}${API_VERSION}/${id}`;
export const getIntegrationPatternUrl = (id: string) =>
	`${process.env.REACT_APP_MOS_CONFIGURATOR_API}${INTEGRATION_PATTERNS_PATH}${API_VERSION}/${id}`;

export const getIntegrationPatternSchemaUrl = (id: string) =>
	`${process.env.REACT_APP_MOS_CONFIGURATOR_API}${INTEGRATION_PATTERNS_PATH}${API_VERSION}/${id}/schema`;

export const getIntegrationTypeUrl = (integrationPatternId: string, integrationTypeId: string) =>
	`${process.env.REACT_APP_MOS_CONFIGURATOR_API}${INTEGRATION_PATTERNS_PATH}${API_VERSION}/${integrationPatternId}${INTEGRATION_TYPES_PATH}/${integrationTypeId}`;
export const getConfigurationRequestsUrl = () =>
	`${process.env.REACT_APP_MOS_CONFIGURATOR_API}${CONFIGURATION_PATH}${API_VERSION}`;
export const getIntakeFieldsUrl = () =>
	`${process.env.REACT_APP_MOS_CONFIGURATOR_API}${INTAKE_FIELDS_PATH}${API_VERSION}`;

export const getPartnersUrl = () => `${process.env.REACT_APP_PARTNER_ROSTER_API}${PARTNERS_PATH}${API_VERSION}`;
export const getIntegrationsUrl = () => `${process.env.REACT_APP_PARTNER_ROSTER_API}${INTEGRATIONS_PATH}${API_VERSION}`;
export const getCredentialsUrl = () => `${process.env.REACT_APP_PARTNER_ROSTER_API}${CREDENTIALS_PATH}${API_VERSION}`;
