import { createSelector } from '@reduxjs/toolkit';

import { State } from 'config/redux/store';

export const selectIntegrationPattern = (state: State) => state.integrationPattern.integrationPattern;
export const selectIntegrationPatternError = (state: State) => state.integrationPattern.error;
export const selectIsLoadingIntegrationPattern = (state: State) => state.integrationPattern.isLoading;
export const selectIntegrationPatternSchema = (state: State) =>
	state.integrationPattern.integrationPatternSchema.schema;
export const selectIntegrationPatternSchemaError = (state: State) =>
	state.integrationPattern.integrationPatternSchema.error;
export const selectIsLoadingIntegrationPatternSchema = (state: State) =>
	state.integrationPattern.integrationPatternSchema.isLoading;

export const selectIntegrationTypes = createSelector(
	[selectIntegrationPattern],
	(integrationPattern) => integrationPattern.integrationTypes
);
