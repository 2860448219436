import { useSpring } from 'react-spring';

import { isStepModeDisable } from 'components/common/Stepper/Stepper.utils';
import { DEFAULT_STEP_HEIGHT } from 'services/constants/common';
import { STEP_MODES } from 'services/types/common';

export const useAnimatedStepHeight = ({ mode, contentHeight }: { mode: STEP_MODES; contentHeight: number }) => {
	const { height } = useSpring({
		height: isStepModeDisable(mode) ? DEFAULT_STEP_HEIGHT : contentHeight,
		from: {
			height: DEFAULT_STEP_HEIGHT,
		},
	});
	return [height];
};
