import { Box, Link, Tab, TabGroup, Text } from '@nike/eds';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Loader } from 'components/common/Loader';
import { NavigationTrail } from 'components/common/NavigationTrail';
import { TabContent } from 'components/common/TabContent';
import { ConfigurationStep } from 'pages/IntegrationPatternConfigurationPage/ConfigurationSteps/ConfigurationStep';
import { DetailsStep } from 'pages/IntegrationPatternConfigurationPage/ConfigurationSteps/DetailsStep';
import { ConfigurationSteps } from 'pages/IntegrationPatternConfigurationPage/IntegrationPatternConfigurationPage.utils';
import { USER_DASHBOARD_ROUTE } from 'services/constants/routes';
import { Breadcrumb, Step } from 'services/types/common';
import { IntegrationPatternDetails } from 'services/types/IntegrationPattern';

export interface IntegrationPatternConfigurationPageProps {
	activeTab: ConfigurationSteps;
	isLoading: boolean;
	isLoadingConfigurationPage: boolean;
	intakeSteps: Step[];
	configurationSteps: Step[];
	integrationPattern: IntegrationPatternDetails;
	breadcrumbs: Breadcrumb[];
	onOpenDetailsStep: () => void;
	onSubmitIntegration: () => void;
	onOpenConfigurationStep: () => void;
	onChangeTab: (tab: ConfigurationSteps) => void;
}

export const IntegrationPatternConfigurationPage = ({
	activeTab,
	isLoading,
	breadcrumbs,
	intakeSteps,
	configurationSteps,
	integrationPattern,
	isLoadingConfigurationPage,
	onChangeTab,
	onSubmitIntegration,
	onOpenDetailsStep,
	onOpenConfigurationStep,
}: IntegrationPatternConfigurationPageProps) => {
	const { t } = useTranslation();

	return (
		<div data-testid="integration-pattern-details-page">
			{(isLoading || isLoadingConfigurationPage) && <Loader type="in-container" />}
			<div className="eds-spacing--px-56 eds-spacing--pt-40">
				<Box className="eds-flex eds-flex--align-items-center eds-flex--justify-content-space-between eds-spacing--mb-16">
					<NavigationTrail
						classNames="eds-spacing--mb-16"
						breadcrumbs={breadcrumbs}
						currentPage={t('pages.integration_pattern_configuration.current_page_label')}
					/>
				</Box>
				{activeTab !== ConfigurationSteps.SUBMITTED && (
					<Box className="eds-spacing--mb-16">
						<TabGroup
							name="tab-group"
							activeId={activeTab}
							onChange={(e) => {
								const tabId = e.target.id as ConfigurationSteps;
								if (tabId === ConfigurationSteps.DETAILS) {
									onOpenDetailsStep();
								} else {
									onChangeTab(e.target.id as ConfigurationSteps);
								}
							}}
						>
							<Tab id={ConfigurationSteps.DETAILS}>{t('pages.integration_pattern_configuration.intake_tab_label')}</Tab>
							<Tab id={ConfigurationSteps.CONFIGURATION}>
								{t('pages.integration_pattern_configuration.tech_tab_label')}
							</Tab>
						</TabGroup>
					</Box>
				)}
				{!isLoadingConfigurationPage && (
					<Box className="eds-background--default eds-spacing--p-24 eds--radii-8 eds-spacing--mb-24">
						{activeTab !== ConfigurationSteps.SUBMITTED && (
							<Text className="eds-spacing--ml-8 eds-spacing--mb-16" font="body-2">
								<Trans
									i18nKey="pages.integration_pattern_configuration.description_message"
									values={{
										integration_pattern_name: integrationPattern.name,
									}}
									components={{
										text: <b />,
									}}
								/>
							</Text>
						)}
						<TabContent id={ConfigurationSteps.CONFIGURATION} activeTab={activeTab}>
							<ConfigurationStep steps={configurationSteps} onSubmit={onSubmitIntegration} />
						</TabContent>
						<TabContent id={ConfigurationSteps.DETAILS} activeTab={activeTab}>
							<DetailsStep steps={intakeSteps} onNext={onOpenConfigurationStep} />
						</TabContent>
						<TabContent id={ConfigurationSteps.SUBMITTED} activeTab={activeTab}>
							<Box className="eds-background--default eds-spacing--p-24 eds--radii-8">
								<Text font="body-2">
									{t('pages.integration_pattern_configuration.submitted_message')}
									<Link as={RouterLink} to={USER_DASHBOARD_ROUTE}>
										{t('pages.integration_pattern_configuration.dashboard_message')}
									</Link>
								</Text>
							</Box>
						</TabContent>
					</Box>
				)}
			</div>
		</div>
	);
};
