import { Box } from '@nike/eds';

import { renderStep } from 'pages/IntegrationPatternConfigurationPage/PreviewContent';
import { Step } from 'services/types/common';

interface PreviewContentProps {
	steps: Step[];
}

export const PreviewContent = ({ steps }: PreviewContentProps) => {
	return <Box>{steps.filter(({ isHidden }) => !isHidden).map(renderStep)}</Box>;
};
