/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { FormSelectField } from 'components/common/FormFieldBuilder/FormFields/FormSelectField/FormSelectField';
import { FormTextField } from 'components/common/FormFieldBuilder/FormFields/FormTextField/FormTextField';
import { FormToggleField } from 'components/common/FormFieldBuilder/FormFields/FormToggleField';
import { Field, FieldType, SelectItem, ValueType } from 'services/types/common';
import { getLinkifiedText, getTextFieldType } from 'utils/utils';

import styles from './FormFieldBuilder.module.scss';

export interface FormFieldBuilderProps {
	field: Field;
	onChange: (field: Field) => void;
}

export const FormFieldBuilder = ({ field, onChange }: FormFieldBuilderProps) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const { t } = useTranslation();

	const hideTooltip = (event: Event) => {
		const subtitleLabel = event.currentTarget as HTMLDivElement;
		const tooltip = (subtitleLabel as any)?.tooltip;

		if (subtitleLabel.classList.contains(styles['container-description-tooltip'])) {
			if (tooltip) {
				document.body.removeChild(tooltip);
				(subtitleLabel as any).tooltip = null;
			}
		} else {
			if (tooltip) {
				tooltip.addEventListener('mouseleave', () => {
					document.body.removeChild(tooltip);
					(subtitleLabel as any).tooltip = null;
				});
			}
		}
	};

	const showTooltip = (event: Event) => {
		const subtitleLabel = event.currentTarget as HTMLElement;
		if (subtitleLabel) {
			const tooltip = document.createElement('div');

			tooltip.className = `eds-label eds-color--text-secondary eds-type--body-3 ${styles['container-description-tooltip']}`;
			tooltip.innerHTML = subtitleLabel?.getAttribute('data-text') || '';
			tooltip.setAttribute('data-tooltip', 'description-tooltip');
			document.body.appendChild(tooltip);

			const rect = subtitleLabel.getBoundingClientRect();

			tooltip.style.top = `${rect.top + window.scrollY + subtitleLabel.offsetHeight - tooltip.offsetHeight}px`;
			tooltip.style.left = `${rect.left + window.scrollX}px`;
			(subtitleLabel as any).tooltip = tooltip;
			tooltip.addEventListener('mouseleave', hideTooltip);
		}
	};

	const handleScroll = () => {
		const tooltip = document.querySelector('[data-tooltip="description-tooltip"]');
		if (tooltip) {
			tooltip.remove();
		}
	};

	useEffect(() => {
		const container = containerRef?.current;
		const mainSection = document.querySelector('[data-testid="main"]');
		if (container) {
			const subtitleLabel = container.querySelector('.eds-label.eds-color--text-secondary.eds-type--body-3');
			if (subtitleLabel) {
				if (subtitleLabel.scrollWidth > subtitleLabel.clientWidth) {
					const description = getLinkifiedText(field?.description || '');

					subtitleLabel.classList.add('description-tooltip');
					subtitleLabel.setAttribute('data-text', description);

					subtitleLabel.addEventListener('mouseenter', showTooltip);
					subtitleLabel.addEventListener('mouseleave', hideTooltip);
				}
			}
		}

		if (mainSection) {
			mainSection.addEventListener('scroll', handleScroll);
		}

		return () => {
			const subtitleLabel = container?.querySelector('.eds-label.eds-color--text-secondary.eds-type--body-3');
			if (subtitleLabel) {
				subtitleLabel.removeEventListener('mouseenter', showTooltip);
				subtitleLabel.removeEventListener('mouseleave', hideTooltip);
			}

			if (mainSection) {
				mainSection.removeEventListener('scroll', handleScroll);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field.description]);

	const handleValueChange = useCallback(
		(updatedValue: ValueType) => {
			const updatedData = { ...field } as Field;
			updatedData.dirty = true;
			updatedData.error = '';
			updatedData.value = updatedValue;
			onChange(updatedData);
		},
		[field, onChange]
	);

	const formField = useMemo(() => {
		const {
			error,
			type,
			label,
			isSecure,
			isDisabled,
			isRequired,
			value,
			placeholder,
			description = '',
			options = [],
		} = field;
		const textType = getTextFieldType(type, isSecure);

		switch (type) {
			case FieldType.BOOLEAN:
				return (
					<FormToggleField
						label={label}
						error={error}
						description={description}
						isChecked={!!value}
						onToggle={handleValueChange}
					/>
				);
			case FieldType.ENUM:
				return (
					<FormSelectField
						label={label}
						error={error}
						isMulti={false}
						isRequired={isRequired}
						description={description}
						placeholder={placeholder || t('common.select_label')}
						value={value as SelectItem | SelectItem[]}
						options={options as SelectItem[]}
						onChange={handleValueChange}
					/>
				);
			case FieldType.ARRAY:
				return (
					<FormSelectField
						label={label}
						error={error}
						isCreatable={true}
						isRequired={isRequired}
						isMulti={true}
						description={description}
						placeholder={placeholder || t('common.select_label')}
						value={value as SelectItem | SelectItem[]}
						options={options as SelectItem[]}
						onChange={handleValueChange}
					/>
				);
			case FieldType.NUMBER:
			case FieldType.INTEGER:
				return (
					<FormTextField
						type={'number'}
						error={error}
						label={label}
						disabled={isDisabled}
						isRequired={isRequired}
						description={description}
						placeholder={placeholder}
						value={value as number}
						onChange={handleValueChange}
					/>
				);
			case FieldType.DATE:
			case FieldType.STRING:
			default:
				return (
					<FormTextField
						type={textType}
						error={error}
						label={label}
						isRequired={isRequired}
						disabled={isDisabled}
						description={description}
						placeholder={placeholder || t('common.enter_text_label')}
						value={value as string}
						onChange={handleValueChange}
					/>
				);
		}
	}, [field, handleValueChange, t]);

	return (
		<>
			{!field?.isHidden && (
				<div ref={containerRef} className={`${styles['container']} mw600 eds-spacing--mb-8`}>
					{formField}
				</div>
			)}
		</>
	);
};
