import { Box, IconButton, Tab, TabGroup } from '@nike/eds';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader } from 'components/common/Loader';
import { NavigationTrail } from 'components/common/NavigationTrail';
import { TabContent } from 'components/common/TabContent';
import { Table } from 'components/common/Table';
import {
	credentialTableColumnsTranslationKeys,
	DashboardTab,
	getCredentialTableRows,
	getIntegrationTableRows,
	integrationTableColumnsTranslationKeys,
} from 'pages/UserDashboardPage/UserDashboardPage.utils';
import { Credential, Integration } from 'services/types/Partner';

import styles from './UserDashboardPage.module.scss';

interface UserDashboardPageProps {
	activeTab: DashboardTab;
	isLoading: boolean;
	credentials: Credential[];
	integrations: Integration[];
	onChangeTab: (tab: DashboardTab) => void;
}

export const UserDashboardPage = ({
	activeTab,
	isLoading,
	credentials,
	integrations,
	onChangeTab,
}: UserDashboardPageProps) => {
	const { t } = useTranslation();

	const integrationRows = useMemo(() => {
		const tableData = getIntegrationTableRows(integrations).map((row) => {
			row.push({
				content: (
					<>
						<IconButton icon="Edit" size="small" label={t('common.edit_label')} />
						<IconButton icon="Delete" size="small" label={t('common.delete_label')} />
					</>
				),
			});

			return row;
		});

		return tableData;
	}, [integrations, t]);

	const credentialRows = useMemo(() => {
		const tableData = getCredentialTableRows(credentials).map((row) => {
			row.push({
				content: (
					<>
						<IconButton icon="Edit" size="small" label={t('common.edit_label')} />
						<IconButton icon="Delete" size="small" label={t('common.delete_label')} />
					</>
				),
			});

			return row;
		});

		return tableData;
	}, [credentials, t]);

	return (
		<div data-testid="user-dashboard-page">
			{isLoading && <Loader type="in-container" />}
			<div className="eds-spacing--px-56 eds-spacing--pt-40">
				<Box className="eds-flex eds-flex--align-items-center eds-flex--justify-content-space-between eds-spacing--mb-16">
					<NavigationTrail classNames="eds-spacing--mb-16" currentPage={t('pages.user_dashboard.current_page_label')} />
				</Box>
				<Box className="eds-spacing--mb-16">
					<TabGroup name="tab-group" activeId={activeTab} onChange={(e) => onChangeTab(e.target.id as DashboardTab)}>
						<Tab id={DashboardTab.INTEGRATIONS}>{t('pages.user_dashboard.integrations_tab_label')}</Tab>
						<Tab id={DashboardTab.CREDENTIALS}>{t('pages.user_dashboard.credentials_tab_label')}</Tab>
					</TabGroup>
				</Box>
				<Box
					className={`eds-background--default eds-spacing--p-24 eds--radii-8 eds-spacing--mb-24 ${styles['container']}`}
				>
					<TabContent id={DashboardTab.INTEGRATIONS} activeTab={activeTab}>
						<Table isLoading={isLoading} columns={integrationTableColumnsTranslationKeys} rows={integrationRows} />
					</TabContent>
					<TabContent id={DashboardTab.CREDENTIALS} activeTab={activeTab}>
						<Table isLoading={isLoading} columns={credentialTableColumnsTranslationKeys} rows={credentialRows} />
					</TabContent>
				</Box>
			</div>
		</div>
	);
};
