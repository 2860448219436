import { Box, Button } from '@nike/eds';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { isStepModePreview, isStepModeUncompleted } from 'components/common/Stepper/Stepper.utils';
import { STEP_MODES } from 'services/types/common';

interface StepContentWrapperProps {
	mode: STEP_MODES;
	editContent?: ReactNode;
	previewContent?: ReactNode;
	onSubmit: () => void;
}

export const StepContentWrapper = ({ mode, editContent, previewContent, onSubmit }: StepContentWrapperProps) => {
	const { t } = useTranslation();

	if (isStepModePreview(mode) || isStepModeUncompleted(mode)) {
		if (!previewContent) return null;

		return <div className="eds-spacing--py-24 eds-spacing--px-32">{previewContent}</div>;
	}

	return (
		<Box className="eds-spacing--py-24 eds-spacing--px-32">
			{editContent}
			<div className="eds-flex eds-flex--align-items-center eds-flex--direction-row-reverse eds-spacing--pt-24">
				<Button type="button" size="small" onClick={onSubmit}>
					{t('common.continue_label')}
				</Button>
			</div>
		</Box>
	);
};
