import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { createIntegration, getIntakeFields } from 'services/api/mos-configurator/MosConfiguratorApi';
import { ResponseError } from 'services/types/Api';
import { Field, FieldType, SelectItem, Step } from 'services/types/common';
import { IntakeForm, Schema } from 'services/types/IntegrationPattern';
import { Partner } from 'services/types/Partner';
import { getFormStepFieldByKey, getHash, getIntegrationName, updateFormStepValueByPath } from 'utils/utils';

enum DisabledField {
	NAME = 'name',
}

enum EmailFields {
	BUSINESS_OWNER_EMAILS = 'businessOwnerEmails',
	TECH_OWNER_EMAILS = 'techOwnerEmails',
	DATA_OWNER_EMAILS = 'dataOwnerEmails',
	ESCALATION_CONTACT = 'escalationContact',
}

export const PARTNER_NAME_KEY = 'partnerName';
export const PARTNER_ID_KEY = 'partnerId';

export interface IntegrationPatternConfigurationState {
	isLoading: boolean;
	error: string;
	hash: string;
	configurationFormSteps: Step[];
	intakeFormSteps: Step[];
	intakeSchema: {
		isLoading: boolean;
		error: string;
		schema: Schema | null;
	};
}

export const initialState: IntegrationPatternConfigurationState = {
	isLoading: false,
	error: '',
	hash: getHash(),
	configurationFormSteps: [],
	intakeFormSteps: [],
	intakeSchema: {
		isLoading: false,
		error: '',
		schema: null,
	},
};

const integrationPatternConfigurationSlice = createSlice({
	name: 'integrationPatternConfigurationSlice',
	initialState,
	reducers: {
		clearConfigurationSubmitError(state: IntegrationPatternConfigurationState) {
			state.error = '';
		},
		setConfigurationFormSteps(
			state: IntegrationPatternConfigurationState,
			{ payload }: PayloadAction<{ steps: Step[] }>
		) {
			state.configurationFormSteps = payload.steps;
		},
		setIntakeFormSteps(state: IntegrationPatternConfigurationState, { payload }: PayloadAction<{ steps: Step[] }>) {
			state.intakeFormSteps = payload.steps;
		},
		updateConfigurationFormStepField(
			state: IntegrationPatternConfigurationState,
			{ payload }: PayloadAction<{ field: Field }>
		) {
			state.configurationFormSteps = updateFormStepValueByPath(state.configurationFormSteps, payload.field);
		},
		updateIntakeFormStepField(
			state: IntegrationPatternConfigurationState,
			{ payload }: PayloadAction<{ field: Field }>
		) {
			state.intakeFormSteps = updateFormStepValueByPath(state.intakeFormSteps, payload.field);
		},
		setIntegrationName(
			state: IntegrationPatternConfigurationState,
			{ payload }: PayloadAction<{ integrationPatternName: string; capabilityName: string; partnerName: string }>
		) {
			const steps = state.intakeFormSteps;
			const integrationName = getIntegrationName(payload, state.hash);
			const nameField = steps.reduce((foundNameField: Field | null, step) => {
				const { fields } = step;
				const foundField = fields.find((field) => field.key === DisabledField.NAME);
				if (foundField) {
					return foundField;
				}

				return foundNameField;
			}, null);

			if (nameField && integrationName !== nameField.value) {
				state.intakeFormSteps = updateFormStepValueByPath(steps, {
					...nameField,
					isDisabled: true,
					value: integrationName,
				});
			}
		},
		setPartnerId(state: IntegrationPatternConfigurationState, { payload }: PayloadAction<{ partnerId: string }>) {
			const partnerIdField = getFormStepFieldByKey(state.intakeFormSteps, PARTNER_ID_KEY);
			if (partnerIdField) {
				state.intakeFormSteps = updateFormStepValueByPath(state.intakeFormSteps, {
					...partnerIdField,
					value: payload.partnerId,
				});
			}
		},
		setPresetFields(state: IntegrationPatternConfigurationState, { payload }: PayloadAction<{ email: string }>) {
			const { email } = payload;
			const steps = state.intakeFormSteps;
			const emailFields = steps?.reduce((foundFields: Field[], step) => {
				const { fields = [] } = step;
				fields.forEach((field) => {
					if (
						[
							EmailFields.BUSINESS_OWNER_EMAILS,
							EmailFields.TECH_OWNER_EMAILS,
							EmailFields.DATA_OWNER_EMAILS,
							EmailFields.ESCALATION_CONTACT,
						].includes(field.key as EmailFields)
					) {
						foundFields.push(field);
					}
				});
				return foundFields;
			}, []);
			const isEmpty = emailFields?.every((field) => Array.isArray(field?.options) && !field?.options.length);
			if (isEmpty) {
				const options = [{ value: email, label: email }];
				const updatedSteps = emailFields?.reduce((newSteps: Step[], field) => {
					return updateFormStepValueByPath(newSteps, {
						...field,
						value: [EmailFields.BUSINESS_OWNER_EMAILS, EmailFields.TECH_OWNER_EMAILS].includes(field.key as EmailFields)
							? options
							: [],
						options,
					});
				}, steps);
				state.intakeFormSteps = updatedSteps;
			}
		},
		setPartners(state: IntegrationPatternConfigurationState, { payload }: PayloadAction<{ partners: Partner[] }>) {
			const steps = state.intakeFormSteps;
			const partnerNameField = getFormStepFieldByKey(steps, PARTNER_NAME_KEY);
			const partnerIdField = getFormStepFieldByKey(steps, PARTNER_ID_KEY);
			if (partnerNameField) {
				const options: SelectItem[] = payload.partners?.map(({ name, partnerId }) => ({
					value: partnerId,
					label: name,
				}));
				state.intakeFormSteps = updateFormStepValueByPath(steps, {
					...partnerNameField,
					type: FieldType.ENUM,
					options,
				});
			}

			if (partnerIdField) {
				state.intakeFormSteps = updateFormStepValueByPath(state.intakeFormSteps, {
					...partnerIdField,
					isHidden: true,
					isConst: true,
				});
			}
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getIntakeFields.pending, (state: IntegrationPatternConfigurationState) => {
				state.intakeSchema.isLoading = true;
			})
			.addCase(
				getIntakeFields.fulfilled,
				(state: IntegrationPatternConfigurationState, { payload }: PayloadAction<IntakeForm>) => {
					const { schema } = payload.objects;
					state.intakeSchema.isLoading = false;
					state.intakeSchema.schema = schema;
				}
			)
			.addCase(getIntakeFields.rejected, (state: IntegrationPatternConfigurationState, { payload }) => {
				state.intakeSchema.isLoading = false;
				state.intakeSchema.error = (payload as ResponseError).errorMessage;
			})
			.addCase(createIntegration.pending, (state: IntegrationPatternConfigurationState) => {
				state.isLoading = true;
			})
			.addCase(createIntegration.fulfilled, (state: IntegrationPatternConfigurationState) => {
				state.isLoading = false;
			})
			.addCase(createIntegration.rejected, (state: IntegrationPatternConfigurationState, { payload }) => {
				state.isLoading = false;
				state.error = (payload as ResponseError).errorMessage;
			});
	},
});

export const {
	setPresetFields,
	setPartners,
	setPartnerId,
	setIntegrationName,
	setIntakeFormSteps,
	setConfigurationFormSteps,
	updateIntakeFormStepField,
	updateConfigurationFormStepField,
	clearConfigurationSubmitError,
} = integrationPatternConfigurationSlice.actions;

export default integrationPatternConfigurationSlice.reducer;
