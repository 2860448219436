import { useEffect, useState } from 'react';
import { useMeasure } from 'react-use';

import { DEFAULT_STEP_HEIGHT } from 'services/constants/common';

export const useUpdateStepContentHeight = () => {
	const [contentHeight, setContentHeight] = useState(DEFAULT_STEP_HEIGHT);
	const [contentRef, { height: measureHeight }] = useMeasure();

	useEffect(() => {
		if (measureHeight) {
			setContentHeight(measureHeight);
		}

		window.addEventListener('resize', () => setContentHeight(measureHeight));

		return window.removeEventListener('resize', () => setContentHeight(measureHeight));
	}, [measureHeight]);

	return [contentRef, contentHeight];
};
