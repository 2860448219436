import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'config/redux/store';
import { IntegrationPatternConfigurationPage } from 'pages/IntegrationPatternConfigurationPage/IntegrationPatternConfigurationPage';
import { ConfigurationSteps } from 'pages/IntegrationPatternConfigurationPage/IntegrationPatternConfigurationPage.utils';
import {
	createIntegration,
	getCapability,
	getIntakeFields,
	getIntegrationPattern,
	getIntegrationPatternSchema,
} from 'services/api/mos-configurator/MosConfiguratorApi';
import { getPartners } from 'services/api/partner-roster/PartnerRosterApi';
import { CAPABILITIES_ROUTE, getCapabilityDetailsRoute } from 'services/constants/routes';
import { selectCapability, selectCapabilityError } from 'services/store/capability/CapabilitySelectors';
import { clearCapabilityError, resetCapabilityState } from 'services/store/capability/CapabilitySlice';
import {
	selectIntegrationPattern,
	selectIntegrationPatternError,
	selectIntegrationPatternSchema,
	selectIntegrationPatternSchemaError,
} from 'services/store/integrationPattern/IntegrationPatternSelectors';
import {
	clearIntegrationPatternError,
	clearIntegrationPatternSchemaError,
} from 'services/store/integrationPattern/IntegrationPatternSlice';
import {
	selectConfigurationFormSteps,
	selectConfigurationPageLoaders,
	selectConfigurationSubmitError,
	selectIntakeFormSteps,
	selectIntakeSchema,
	selectIntegrationForm,
	selectIsIntegrationSubmitting,
	selectIsLoadingConfigurationPage,
	selectParamsForNameIntegration,
	selectPartnerName,
} from 'services/store/integrationPatternConfiguration/IntegrationPatternConfigurationSelectors';
import {
	clearConfigurationSubmitError,
	setConfigurationFormSteps,
	setIntakeFormSteps,
	setIntegrationName,
	setPartnerId,
	setPartners,
	setPresetFields,
} from 'services/store/integrationPatternConfiguration/IntegrationPatternConfigurationSlice';
import { selectPartners } from 'services/store/roster/RosterSelectors';
import { selectUser } from 'services/store/user/UserSelectors';
import { Step } from 'services/types/common';
import { useSnack } from 'utils/hooks/snack';
import {
	isValidFormStep,
	transformSchemaToSteps,
	updateFormSpepWithError,
	updateStepsWithDependentRequired,
} from 'utils/utils';

export const IntegrationPatternConfigurationPageContainer = () => {
	const snack = useSnack();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [activeConfigurationTab, setActiveConfigurationTab] = useState<ConfigurationSteps>(ConfigurationSteps.DETAILS);
	const { capabilityId = '', integrationPatternId = '' } = useParams();

	const capabilityError = useSelector(selectCapabilityError);
	const integrationPatternError = useSelector(selectIntegrationPatternError);
	const integrationPatternSchemaError = useSelector(selectIntegrationPatternSchemaError);
	const configurationSubmitError = useSelector(selectConfigurationSubmitError);

	const user = useSelector(selectUser);
	const partners = useSelector(selectPartners);
	const capability = useSelector(selectCapability);
	const integrationPattern = useSelector(selectIntegrationPattern);
	const integrationPatternSchema = useSelector(selectIntegrationPatternSchema);
	const intakeSchema = useSelector(selectIntakeSchema);

	const isIntegrationSubmitting = useSelector(selectIsIntegrationSubmitting);
	const isLoadingConfigurationPage = useSelector(selectIsLoadingConfigurationPage);
	const configurationPageLoaders = useSelector(selectConfigurationPageLoaders);

	const configurationFormSteps = useSelector(selectConfigurationFormSteps);
	const intakeFormSteps = useSelector(selectIntakeFormSteps);
	const partnerName = useSelector(selectPartnerName);
	const integrationForm = useSelector(selectIntegrationForm);
	const paramsForNameIntegration = useSelector(selectParamsForNameIntegration);

	useEffect(() => {
		if (capabilityId && capability.capabilityId !== capabilityId) {
			dispatch(resetCapabilityState());
			dispatch(getCapability({ capabilityId }));
		}
	}, [capability.capabilityId, capabilityId, dispatch]);

	useEffect(() => {
		if (partnerName && Object.keys(partnerName).length) {
			const { value } = partnerName;
			dispatch(setPartnerId({ partnerId: value }));
		}
	}, [partnerName, dispatch]);

	useEffect(() => {
		const { email = '' } = user;
		if (email && Array.isArray(intakeFormSteps) && intakeFormSteps.length) {
			dispatch(setPresetFields({ email }));
		}
	}, [user, intakeFormSteps, dispatch]);

	useEffect(() => {
		const { integrationPatternName } = paramsForNameIntegration;
		if (integrationPatternName) {
			dispatch(setIntegrationName(paramsForNameIntegration));
		}
	}, [paramsForNameIntegration, dispatch]);

	useEffect(() => {
		if (integrationPatternId) {
			dispatch(getIntegrationPattern({ integrationPatternId }));
			dispatch(getIntegrationPatternSchema({ integrationPatternId }));
		}
	}, [integrationPatternId, dispatch]);

	useEffect(() => {
		if (integrationPatternSchema) {
			const steps = transformSchemaToSteps(integrationPatternSchema);
			const stepsWithDependentRequired = updateStepsWithDependentRequired(steps, integrationPatternSchema);

			dispatch(setConfigurationFormSteps({ steps: stepsWithDependentRequired }));
		}
	}, [integrationPatternSchema, dispatch]);

	useEffect(() => {
		if (intakeSchema && Array.isArray(partners) && partners.length) {
			const steps = transformSchemaToSteps(intakeSchema);
			const stepsWithDependentRequired = updateStepsWithDependentRequired(steps, intakeSchema);

			dispatch(setIntakeFormSteps({ steps: stepsWithDependentRequired }));
			dispatch(setPartners({ partners }));
		} else {
			if (!intakeSchema) {
				dispatch(getIntakeFields());
			} else {
				dispatch(getPartners());
			}
		}
	}, [intakeSchema, partners, dispatch]);

	useEffect(() => {
		if (integrationPatternError) {
			snack.error(integrationPatternError);
			dispatch(clearIntegrationPatternError());
		}

		if (integrationPatternSchemaError) {
			snack.error(integrationPatternSchemaError);
			dispatch(clearIntegrationPatternSchemaError());
		}

		if (capabilityError) {
			snack.error(capabilityError);
			dispatch(clearCapabilityError());
		}

		if (configurationSubmitError) {
			snack.error(configurationSubmitError);
			dispatch(clearConfigurationSubmitError());
		}
	}, [
		snack,
		capabilityError,
		configurationSubmitError,
		integrationPatternError,
		integrationPatternSchemaError,
		dispatch,
	]);

	const handleChangeConfigurationTab = (tab: ConfigurationSteps) => setActiveConfigurationTab(tab);

	const handleOpenDetailsStep = useCallback(() => {
		handleChangeConfigurationTab(ConfigurationSteps.DETAILS);
	}, []);

	const handleOpenConfigurationStep = useCallback(() => {
		handleChangeConfigurationTab(ConfigurationSteps.CONFIGURATION);
	}, []);

	const getIsValidFormSteps = (steps: Step[], onUpdate: (steps: Step[]) => void) => {
		const validatedFormSteps = steps.map((step, index) => ({
			isValid: step.isHidden ? true : isValidFormStep(step, steps),
			stepId: index,
		}));
		const isValidForm = validatedFormSteps.every(({ isValid }) => isValid);

		if (!isValidForm) {
			const invalidFormStep = validatedFormSteps.find(({ isValid }) => !isValid);
			if (invalidFormStep) {
				const updatedInvalidFormStepWithError = updateFormSpepWithError(steps, invalidFormStep.stepId);

				onUpdate(updatedInvalidFormStepWithError);
			}
		}

		return isValidForm;
	};

	const handleSubmitIntegration = useCallback(() => {
		const isValidConfigurationForm = getIsValidFormSteps(configurationFormSteps, (steps) =>
			dispatch(setConfigurationFormSteps({ steps }))
		);
		if (!isValidConfigurationForm) {
			handleOpenConfigurationStep();
			return;
		}

		const isValidIntakeForm = getIsValidFormSteps(intakeFormSteps, (steps) => dispatch(setIntakeFormSteps({ steps })));
		if (!isValidIntakeForm) {
			handleOpenDetailsStep();
			return;
		}

		dispatch(createIntegration({ form: integrationForm })).then(() =>
			handleChangeConfigurationTab(ConfigurationSteps.SUBMITTED)
		);
	}, [
		intakeFormSteps,
		configurationFormSteps,
		integrationForm,
		handleOpenDetailsStep,
		handleOpenConfigurationStep,
		dispatch,
	]);

	const breadcrumbs = useMemo(
		() => [
			{
				label: t('pages.capabilities.header_label'),
				url: CAPABILITIES_ROUTE,
				isLoading: false,
			},
			{
				label: capability?.name,
				url: getCapabilityDetailsRoute(capabilityId),
				isLoading: configurationPageLoaders.isLoadingCapability,
			},
			{
				label: integrationPattern?.name,
				url: getCapabilityDetailsRoute(capabilityId),
				isLoading: configurationPageLoaders.isLoadingIntegrationPattern,
			},
		],
		[integrationPattern?.name, capability?.name, capabilityId, configurationPageLoaders, t]
	);

	return (
		<IntegrationPatternConfigurationPage
			isLoading={isIntegrationSubmitting}
			isLoadingConfigurationPage={isLoadingConfigurationPage}
			breadcrumbs={breadcrumbs}
			activeTab={activeConfigurationTab}
			integrationPattern={integrationPattern}
			intakeSteps={intakeFormSteps}
			configurationSteps={configurationFormSteps}
			onOpenDetailsStep={handleOpenDetailsStep}
			onSubmitIntegration={handleSubmitIntegration}
			onOpenConfigurationStep={handleOpenConfigurationStep}
			onChangeTab={handleChangeConfigurationTab}
		/>
	);
};
