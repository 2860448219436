import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { Step } from 'components/common/Stepper/Step';
import { StepContentWrapper } from 'components/common/Stepper/StepContentWrapper';
import {
	isStepModeDisable,
	isStepModeEdit,
	isStepModePreview,
	isStepModeUncompleted,
} from 'components/common/Stepper/Stepper.utils';
import { StepResultWrapper } from 'components/common/Stepper/StepResultWrapper';
import { STEP_MODES, Step as StepType } from 'services/types/common';

interface StepperViewProps {
	steps: StepType[];
	activeStepIdx: number;
	resultStepIdx: number;
	resultStepMode: STEP_MODES;
	resultContent: ReactNode;
	saveBtnTitlePath: string;
	renderEditContent: (step: StepType) => ReactNode;
	handleSaveClick: () => void;
	handleStepClick: (index: number) => void;
	handleContinueClick: ({ currentStepIdx }: { currentStepIdx: number }) => void;
}

export const StepperView = ({
	steps,
	activeStepIdx,
	resultStepIdx,
	resultStepMode,
	saveBtnTitlePath,
	resultContent,
	renderEditContent,
	handleStepClick,
	handleSaveClick,
	handleContinueClick,
}: StepperViewProps) => {
	const { t } = useTranslation();

	const renderStep = (step: StepType, stepIdx: number) => {
		const { title, mode, formName } = step;
		const hasStepHeaderHandler =
			activeStepIdx !== 0 &&
			(activeStepIdx > stepIdx || activeStepIdx <= steps.length) &&
			(isStepModePreview(mode) || isStepModeUncompleted(mode));

		const onStepClick = hasStepHeaderHandler ? () => handleStepClick(stepIdx) : undefined;

		const onContinueClick = () => {
			handleContinueClick({ currentStepIdx: stepIdx });
		};

		const editContent = renderEditContent && renderEditContent(step);

		return (
			<Step key={formName} name={title} mode={mode} stepIndex={stepIdx} onStepClick={onStepClick}>
				{!isStepModeDisable(mode) && (
					<StepContentWrapper editContent={editContent} mode={mode} onSubmit={onContinueClick} />
				)}
			</Step>
		);
	};

	return (
		<div data-testid="stepper">
			{steps.map(renderStep)}
			<Step name={t('common.preview_label')} mode={resultStepMode} stepIndex={resultStepIdx}>
				{isStepModeEdit(resultStepMode) && (
					<StepResultWrapper content={resultContent} submitButtonLable={saveBtnTitlePath} onSubmit={handleSaveClick} />
				)}
			</Step>
		</div>
	);
};
