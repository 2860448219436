/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon, Text } from '@nike/eds';
import { useMemo } from 'react';
import { animated, SpringValue, TransitionFn } from 'react-spring';

import { isStepModePreview, isStepModeUncompleted } from 'components/common/Stepper/Stepper.utils';
import { STEP_MODES } from 'services/types/common';

import styles from './StepHeader.module.scss';

interface StepHeaderViewProps {
	title: string;
	mode: STEP_MODES;
	transitions: TransitionFn<
		{ isPreview: boolean; isUncomplete: boolean },
		{
			position: string;
			display: string;
			alignItems: string;
			justifyContent: string;
			opacity: number;
		}
	>;
	titleColor: SpringValue<string>;
	arrowColor: SpringValue<string>;
	arrowBackgroundColor: SpringValue<string>;
	arrowBorderColor: SpringValue<string>;
	onStepClick?: () => void;
}

export const StepHeaderView = ({
	title,
	mode,
	transitions,
	titleColor,
	arrowColor,
	arrowBackgroundColor,
	arrowBorderColor,
	onStepClick,
}: StepHeaderViewProps) => {
	const titlePrependComponent = useMemo(() => {
		const renderTransition = (
			props: { [key: string]: SpringValue<any> },
			{ isPreview }: { isPreview: boolean; isUncomplete: boolean }
		) => {
			return isPreview ? (
				<animated.div style={{ ...props, opacity: 1 }}>
					<Icon data-testid="preview-icon" name="Check" color="white" size="m" />
				</animated.div>
			) : (
				<animated.span className={styles['step-header__step-idx']} style={{ ...props, color: arrowColor, opacity: 1 }}>
					<Icon data-testid="no-preview-icon" name="Check" size="m" />
				</animated.span>
			);
		};

		return transitions(renderTransition);
	}, [arrowColor, transitions]);

	const pencilPrependComponent = useMemo(() => {
		const renderTransition = (
			props: { [key: string]: SpringValue<any> },
			{ isPreview, isUncomplete }: { isPreview: boolean; isUncomplete: boolean }
		) =>
			(isPreview || isUncomplete) &&
			onStepClick && (
				<animated.div style={{ ...props, opacity: 1 }} className={styles['step-header__pencil-icon-container']}>
					<Icon data-testid="pencil-icon" name="Edit" size="m" />
				</animated.div>
			);

		return transitions(renderTransition);
	}, [transitions, onStepClick]);

	return (
		<div
			data-testid="step-header"
			className={`${styles['step-header']} ${
				isStepModePreview(mode) || isStepModeUncompleted(mode) ? 'cursor--pointer' : ''
			}`}
			onClick={onStepClick}
		>
			<div className={styles['step-header__title']}>
				<animated.div
					className={styles['step-header__title-prepend-container']}
					style={{
						backgroundColor: arrowBackgroundColor,
						borderColor: arrowBorderColor,
					}}
				>
					{titlePrependComponent}
				</animated.div>
				<animated.span>
					<Text font="title-5">
						<animated.span style={{ color: titleColor }}>{title}</animated.span>
					</Text>
				</animated.span>
			</div>
			{onStepClick && (
				<animated.div className={styles['step-header__pencil-prepared-container']}>
					{pencilPrependComponent}
				</animated.div>
			)}
		</div>
	);
};
