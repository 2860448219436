import { Select } from '@nike/eds';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { SelectItem } from 'services/types/common';

export interface FormSelectFieldProps {
	label: string;
	error: string;
	description: string;
	isCreatable?: boolean;
	isRequired?: boolean;
	isMulti?: boolean;
	value: SelectItem | SelectItem[];
	placeholder?: string;
	options: SelectItem[];
	onChange: (selected: SelectItem | SelectItem[]) => void;
}

export const FormSelectField = ({
	isMulti,
	isCreatable,
	isRequired,
	error,
	label,
	description,
	value,
	options,
	placeholder,
	onChange,
}: FormSelectFieldProps) => {
	const normalizedLabel = useMemo(() => {
		if (error) {
			return label;
		}

		return isRequired ? `${label} *` : label;
	}, [error, isRequired, label]);

	return (
		<Select
			id={uuidv4()}
			className={`${description ? '' : 'eds-spacing--mt-36'} ${error ? '' : 'eds-spacing--pb-24'}`}
			hasErrors={!!error}
			errorMessage={error}
			isMulti={isMulti}
			isCreatable={isCreatable}
			placeholder={placeholder}
			value={Object.keys(value).length ? value : null}
			options={options}
			label={normalizedLabel}
			subtitle={description}
			onChange={(selected) => onChange(selected as SelectItem | SelectItem[])}
		/>
	);
};
