import { Table as EdsTable, TableCell, TableHeading, Text } from '@nike/eds';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export interface TableColumnItem {
	labelTranslateKey: string;
}

export interface TableRowItem {
	content: string | number | boolean | ReactNode;
}

interface TableProps {
	columns: TableColumnItem[];
	rows: TableRowItem[][];
	isLoading?: boolean;
}

export const Table = ({ columns, rows, isLoading }: TableProps) => {
	const { t } = useTranslation();

	const tableColumns = useMemo(
		() => columns?.map(({ labelTranslateKey }) => ({ label: t(labelTranslateKey) })),
		[columns, t]
	);

	return (
		<div data-testid="table">
			<EdsTable className="full-width">
				<thead>
					<tr>
						{tableColumns?.map((column) => (
							<TableHeading style={{ minWidth: '130px' }} key={uuidv4()}>
								{column.label}
							</TableHeading>
						))}
					</tr>
				</thead>
				{!rows?.length && !isLoading ? (
					<tr>
						<TableCell colSpan={columns?.length}>
							<Text font="body-2" style={{ margin: '0 auto' }}>
								{t('common.no_content_provided_message')}
							</Text>
						</TableCell>
					</tr>
				) : (
					<tbody>
						{rows.map((row) => (
							<tr key={uuidv4()}>
								{row.map((cell) => {
									return (
										<TableCell style={{ wordBreak: 'break-word' }} key={uuidv4()}>
											{cell.content}
										</TableCell>
									);
								})}
							</tr>
						))}
					</tbody>
				)}
			</EdsTable>
		</div>
	);
};
