import { StatusIndicator as StatusIndicatorComponent } from '@nike/eds';

import { TableColumnItem, TableRowItem } from 'components/common/Table/Table';
import { translate } from 'config/localization/i18n';
import { DD_MMM_YYYY } from 'services/constants/common';
import { StatusIndicator } from 'services/types/common';
import { Credential, Integration } from 'services/types/Partner';
import { addDaysToDate, formatDate } from 'utils/utils';

export enum DashboardTab {
	INTEGRATIONS = 'integrations',
	CREDENTIALS = 'credentials',
}

export enum IntakeStatus {
	PROVISIONING = 'provisioning',
	SUCCESS = 'success',
	ERROR = 'error',
}

export enum CredentialStatus {
	ACTIVE = 'active',
	EXPIRED = 'expired',
	EXPIRING_SOON = 'expiring_soon',
}

enum IntegrationTableField {
	NAME = 'name',
	STATUS = 'status',
	INTEGRATION_ID = 'integrationId',
	GEOGRAPHY = 'geography',
	END_DATE = 'endDate',
	CREATED_DATE = 'createdDate',
}

enum CredentialTableField {
	NAME = 'name',
	STATUS = 'status',
	CREDENTIAL_ID = 'credentialId',
	GEOGRAPHY = 'geography',
	END_DATE = 'endDate',
	CREATED_DATE = 'createdDate',
}

const EXPIRE_SOON_DAYS_DELTA = 14;

export const integrationTableColumnsTranslationKeys: TableColumnItem[] = [
	{
		labelTranslateKey: 'pages.user_dashboard.integration_table_columns.name_of_integration_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.integration_table_columns.status_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.integration_table_columns.integration_id_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.integration_table_columns.geography_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.integration_table_columns.end_date_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.integration_table_columns.created_date_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.integration_table_columns.actions_label',
	},
];

export const credentialTableColumnsTranslationKeys: TableColumnItem[] = [
	{
		labelTranslateKey: 'pages.user_dashboard.credential_table_columns.name_of_credential_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.credential_table_columns.status_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.credential_table_columns.credential_id_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.credential_table_columns.geography_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.credential_table_columns.end_date_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.credential_table_columns.created_date_label',
	},
	{
		labelTranslateKey: 'pages.user_dashboard.credential_table_columns.actions_label',
	},
];

export const getIntakeStatusIndicator = (status: string): StatusIndicator => {
	if (status === IntakeStatus.SUCCESS) {
		return StatusIndicator.SUCCESS;
	}

	if (status === IntakeStatus.PROVISIONING) {
		return StatusIndicator.NEUTRAL;
	}

	if (status === IntakeStatus.ERROR) {
		return StatusIndicator.DANGER;
	}

	return StatusIndicator.INACTIVE;
};

export const getIntakeStatusLabel = (status: string): string => {
	if (status === IntakeStatus.SUCCESS) {
		return translate('common.success_status_label');
	}

	if (status === IntakeStatus.PROVISIONING) {
		return translate('common.provisioning_status_label');
	}

	if (status === IntakeStatus.ERROR) {
		return translate('common.error_status_label');
	}

	return translate('common.status_is_missigng_label');
};

export const getCredentialsStatusIndicator = (status: CredentialStatus): StatusIndicator => {
	if (status === CredentialStatus.ACTIVE) {
		return StatusIndicator.SUCCESS;
	}

	if (status === CredentialStatus.EXPIRING_SOON) {
		return StatusIndicator.WARNING;
	}

	return StatusIndicator.DANGER;
};

export const getCredentialsStatusLabel = (status: CredentialStatus): string => {
	if (status === CredentialStatus.ACTIVE) {
		return translate('common.active_status_label');
	}

	if (status === CredentialStatus.EXPIRING_SOON) {
		return translate('common.expired_soon_status_label');
	}

	return translate('common.expired_status_label');
};

export const getCredentialsStatus = (
	endDate: string | null,
	lastReviewDate: string,
	reviewFreqDays: number
): CredentialStatus => {
	const today = new Date();
	const expiringSoonDate = addDaysToDate(today, EXPIRE_SOON_DAYS_DELTA);
	const activeDate = addDaysToDate(today, EXPIRE_SOON_DAYS_DELTA * 2);

	const sanitizedEndDate = !endDate ? activeDate : new Date(endDate);
	const sanitizedLastReviewDate = addDaysToDate(new Date(lastReviewDate), Number(reviewFreqDays));

	if (sanitizedEndDate <= today || sanitizedLastReviewDate <= today) {
		return CredentialStatus.EXPIRED;
	} else if (
		(today <= sanitizedEndDate && sanitizedEndDate <= expiringSoonDate) ||
		(today <= sanitizedLastReviewDate && sanitizedLastReviewDate <= expiringSoonDate)
	) {
		return CredentialStatus.EXPIRING_SOON;
	}

	return CredentialStatus.ACTIVE;
};

export const getIntegrationTableRows = (integrations: Integration[]): TableRowItem[][] => {
	return (
		integrations?.map((integration) => {
			const row: TableRowItem[] = [
				IntegrationTableField.NAME,
				IntegrationTableField.STATUS,
				IntegrationTableField.INTEGRATION_ID,
				IntegrationTableField.GEOGRAPHY,
				IntegrationTableField.END_DATE,
				IntegrationTableField.CREATED_DATE,
			].reduce((tableRow: TableRowItem[], integrationKey) => {
				const content = (integration[integrationKey as keyof Integration] as string) ?? '';
				if (IntegrationTableField.STATUS === integrationKey) {
					const label = getIntakeStatusLabel(content);
					const status = getIntakeStatusIndicator(content);

					return [
						...tableRow,
						{
							content: (
								<StatusIndicatorComponent
									data-testid="status-indicator"
									className="wordbreak--initial"
									label={label}
									status={status}
								/>
							),
						},
					];
				}

				if (!content) {
					return [...tableRow, { content: '' }];
				}

				if ([IntegrationTableField.END_DATE, IntegrationTableField.CREATED_DATE].includes(integrationKey)) {
					return [...tableRow, { content: formatDate(content, DD_MMM_YYYY) }];
				}

				return [...tableRow, { content }];
			}, []);

			return row;
		}) || []
	);
};

export const getCredentialTableRows = (credentials: Credential[]): TableRowItem[][] => {
	return (
		credentials?.map((credential) => {
			const row: TableRowItem[] = [
				CredentialTableField.NAME,
				CredentialTableField.STATUS,
				CredentialTableField.CREDENTIAL_ID,
				CredentialTableField.GEOGRAPHY,
				CredentialTableField.END_DATE,
				CredentialTableField.CREATED_DATE,
			].reduce((tableRow: TableRowItem[], credentialKey) => {
				const content = (credential[credentialKey as keyof Credential] as string) ?? '';
				if (CredentialTableField.STATUS === credentialKey) {
					const credentialStatus = getCredentialsStatus(
						credential?.endDate,
						credential?.lastReviewDate,
						credential?.reviewFreqDays
					);
					const label = getCredentialsStatusLabel(credentialStatus);
					const status = getCredentialsStatusIndicator(credentialStatus);

					return [
						...tableRow,
						{
							content: (
								<StatusIndicatorComponent
									data-testid="status-indicator"
									className="wordbreak--initial"
									label={label}
									status={status}
								/>
							),
						},
					];
				}

				if (!content) {
					return [...tableRow, { content: '' }];
				}

				if ([CredentialTableField.END_DATE, CredentialTableField.CREATED_DATE].includes(credentialKey)) {
					return [...tableRow, { content: formatDate(content, DD_MMM_YYYY) }];
				}

				return [...tableRow, { content }];
			}, []);

			return row;
		}) || []
	);
};
