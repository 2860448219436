import { Spinner } from '@nike/eds';
import { useTranslation } from 'react-i18next';

import styles from './Loader.module.scss';

interface LoaderProps {
	classNames?: string;
	height?: string;
	size?: 'large' | 'medium';
	type?: 'full-screen' | 'full-screen--transparent' | 'in-container';
}

export const Loader = ({ classNames, type = 'in-container', size = 'large', height = '100%' }: LoaderProps) => {
	const { t } = useTranslation();

	return (
		<div
			data-testid="loader-container-testid"
			className={`${styles['container']} ${styles['container-' + type]} ${classNames ?? ''}`}
			style={{ height }}
		>
			<div className={styles['spinner']} aria-label={t('common.loading')}>
				<Spinner data-testid="spinner-testid" size={size} />
			</div>
		</div>
	);
};
