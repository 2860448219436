import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCredentials, getIntegrations, getPartners } from 'services/api/partner-roster/PartnerRosterApi';
import { ResponseError } from 'services/types/Api';
import { Credential, Integration, Partner, PartnersApi } from 'services/types/Partner';

export interface RosterState {
	partners: {
		isLoading: boolean;
		error: string;
		items: Partner[];
	};
	integrations: {
		isLoading: boolean;
		error: string;
		items: Integration[];
	};
	credentials: {
		isLoading: boolean;
		error: string;
		items: Credential[];
	};
}

export const initialState: RosterState = {
	partners: {
		isLoading: false,
		error: '',
		items: [],
	},
	integrations: {
		isLoading: false,
		error: '',
		items: [],
	},
	credentials: {
		isLoading: false,
		error: '',
		items: [],
	},
};

const rosterSlice = createSlice({
	name: 'roster',
	initialState,
	reducers: {
		clearPartnersError(state: RosterState) {
			state.partners.error = '';
		},
		clearIntegrationsError(state: RosterState) {
			state.integrations.error = '';
		},
		clearCredentialsError(state: RosterState) {
			state.credentials.error = '';
		},
		clearPartners(state: RosterState) {
			state.partners.items = [];
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getPartners.pending, (state: RosterState) => {
				state.partners.isLoading = true;
			})
			.addCase(getPartners.fulfilled, (state: RosterState, { payload }: PayloadAction<PartnersApi>) => {
				state.partners.isLoading = false;
				state.partners.items = payload.partners;
			})
			.addCase(getPartners.rejected, (state: RosterState, { payload }) => {
				state.partners.isLoading = false;
				state.partners.error = (payload as ResponseError).errorMessage;
			})
			.addCase(getIntegrations.pending, (state: RosterState) => {
				state.integrations.isLoading = true;
			})
			.addCase(
				getIntegrations.fulfilled,
				(state: RosterState, { payload }: PayloadAction<{ integrations: Integration[] }>) => {
					state.integrations.isLoading = false;
					state.integrations.items = payload.integrations;
				}
			)
			.addCase(getIntegrations.rejected, (state: RosterState, { payload }) => {
				state.integrations.isLoading = false;
				state.integrations.error = (payload as ResponseError).errorMessage;
			})
			.addCase(getCredentials.pending, (state: RosterState) => {
				state.credentials.isLoading = true;
			})
			.addCase(
				getCredentials.fulfilled,
				(state: RosterState, { payload }: PayloadAction<{ credentials: Credential[] }>) => {
					state.credentials.isLoading = false;
					state.credentials.items = payload.credentials;
				}
			)
			.addCase(getCredentials.rejected, (state: RosterState, { payload }) => {
				state.credentials.isLoading = false;
				state.credentials.error = (payload as ResponseError).errorMessage;
			});
	},
});

export const { clearPartners, clearPartnersError, clearCredentialsError, clearIntegrationsError } = rosterSlice.actions;

export default rosterSlice.reducer;
