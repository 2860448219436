import { Box } from '@nike/eds';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FormFieldBuilder } from 'components/common/FormFieldBuilder';
import { Stepper } from 'components/common/Stepper';
import { useDispatch } from 'config/redux/store';
import { PreviewContent } from 'pages/IntegrationPatternConfigurationPage/PreviewContent';
import {
	setIntakeFormSteps,
	updateIntakeFormStepField,
} from 'services/store/integrationPatternConfiguration/IntegrationPatternConfigurationSlice';
import { Field, Step } from 'services/types/common';
import { isValidFormStep } from 'utils/utils';

import styles from '../../IntegrationPatternConfigurationPage.module.scss';

interface DetailsStepProps {
	steps: Step[];
	onNext: () => void;
}

export const DetailsStep = ({ steps, onNext }: DetailsStepProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleIntakeFormFieldChange = (field: Field) => {
		dispatch(updateIntakeFormStepField({ field }));
	};

	const handleStepsChange = (updatedSteps: Step[]) => {
		dispatch(setIntakeFormSteps({ steps: updatedSteps }));
	};

	const handleValidateStep = useCallback((currentStep: Step) => isValidFormStep(currentStep, steps), [steps]);

	const renderEditContent = (step: Step) => {
		return (
			<Box className={'eds-grid eds-grid--xxl-cols-2 eds-spacing--mb-16'}>
				{step?.fields?.map((field: Field, index: number) => (
					<FormFieldBuilder
						key={index}
						field={field}
						onChange={(updatedField) => handleIntakeFormFieldChange(updatedField)}
					/>
				))}
			</Box>
		);
	};

	return (
		<Box data-testid="details-step" className={`eds-spacing--p-8 eds-spacing--pb-16 ${styles['container']}`}>
			<Stepper
				steps={steps}
				submitButtonLabel={t('common.next_label')}
				renderEditContent={renderEditContent}
				resultContent={<PreviewContent steps={steps} />}
				validateStep={handleValidateStep}
				onSubmit={onNext}
				onStepsChange={handleStepsChange}
			/>
		</Box>
	);
};
