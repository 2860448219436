import { Box, Card, Text } from '@nike/eds';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ApiDocs from 'assets/imgs/gocs.jpg';
import Capabilities from 'assets/imgs/landing-brand.jpg';
import { CustomCard } from 'components/common/CustomCard';
import { API_DOCS_ROUTE, CAPABILITIES_ROUTE } from 'services/constants/routes';

export const LandingPage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleNavigate = (route: string) => () => navigate(route);

	return (
		<div data-testid="landing-page" className="eds-spacing--px-56 eds-spacing--pt-40">
			<div className="eds-flex eds-flex--align-items-center eds-flex--justify-content-space-between eds-spacing--mb-32">
				<Text font="title-3" color="black">
					{t('pages.landing.title_label')}
				</Text>
			</div>
			<Card className="eds-spacing--mb-32">
				<Text font="body-2">{t('pages.landing.greeting_message')}</Text>
			</Card>
			<Box className="eds-grid eds-grid--m-cols-2 eds-grid--items-center">
				<CustomCard
					imgPath={Capabilities}
					classNames="h400"
					objectFit="cover"
					textColor="white"
					message={t('pages.landing.capabilities_label')}
					altMessage={t('pages.landing.capabilities_label')}
					onClick={handleNavigate(CAPABILITIES_ROUTE)}
				/>
				<CustomCard
					imgPath={ApiDocs}
					classNames="h400"
					objectFit="cover"
					textColor="white"
					message={t('pages.landing.api_docs_label')}
					altMessage={t('pages.landing.api_docs_label')}
					onClick={handleNavigate(API_DOCS_ROUTE)}
				/>
			</Box>
		</div>
	);
};
