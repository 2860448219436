import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCapability } from 'services/api/mos-configurator/MosConfiguratorApi';
import { ResponseError } from 'services/types/Api';
import { CapabilityDetails } from 'services/types/Capability';

export interface CapabilityState {
	isLoading: boolean;
	error: string;
	capability: CapabilityDetails;
}

export const initialState: CapabilityState = {
	isLoading: false,
	error: '',
	capability: {
		name: '',
		description: '',
		visualDescription: [],
		integrationPatterns: [],
		createdDate: '',
		updatedDate: null,
		thumbnail: {
			imgLink: '',
			alt: '',
		},
	},
};

const capabilitySlice = createSlice({
	name: 'capability',
	initialState,
	reducers: {
		resetCapabilityState(state: CapabilityState) {
			state.error = '';
			state.capability = initialState.capability;
		},
		clearCapabilityError(state: CapabilityState) {
			state.error = '';
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getCapability.pending, (state: CapabilityState) => {
				state.isLoading = true;
			})
			.addCase(getCapability.fulfilled, (state: CapabilityState, { payload }: PayloadAction<CapabilityDetails>) => {
				state.isLoading = false;
				state.capability = payload;
			})
			.addCase(getCapability.rejected, (state: CapabilityState, { payload }) => {
				state.isLoading = false;
				state.error = (payload as ResponseError).errorMessage;
			});
	},
});

export const { clearCapabilityError, resetCapabilityState } = capabilitySlice.actions;

export default capabilitySlice.reducer;
