import { useEffect, useState } from 'react';

export const DEFAULT_DELAY = 500;

export const useDebounce = (value: string, delay: number = DEFAULT_DELAY) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => clearTimeout(timeoutId);
	}, [value, delay]);

	return debouncedValue;
};
