import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const translate = (key: string, parameters?: { [key: string]: string | number }) => i18next.t(key, parameters);

i18next
	.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		debug: process.env.NODE_ENV !== 'production',
		backend: {
			loadPath: '/locales/{{lng}}/translation.json',
		},
		interpolation: {
			escapeValue: false,
		},
	});

export default i18next;
