import { Modal } from '@nike/eds';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ErrorFallback.module.scss';

interface ErrorFallbackProps {
	error: {
		message: string;
	};
}

export const ErrorFallback = ({ error }: ErrorFallbackProps) => {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(true);

	return (
		<Modal onDismiss={() => setVisible(!visible)} isOpen={visible} headerSlot={t('common.default_error_message')}>
			<div className={styles['container']}>
				<pre>{error.message}</pre>
			</div>
		</Modal>
	);
};
