import { useCallback } from 'react';
import { useSpring, useTransition } from 'react-spring';

import {
	ARROW_BACKGROUND_COLOR_DISABLE,
	ARROW_BORDER_COLOR_DISABLE,
	ARROW_COLOR_DISABLE,
	getNumberBackgroundColor,
	getNumberBorderColor,
	getNumberColor,
	getTitleColor,
	TITLE_COLOR_DISABLE,
} from 'components/common/Stepper/Step/StepHeader/StepHeader.utils';
import { StepHeaderView } from 'components/common/Stepper/Step/StepHeader/StepHeaderView';
import { isStepModePreview, isStepModeUncompleted } from 'components/common/Stepper/Stepper.utils';
import { STEP_MODES } from 'services/types/common';

interface StepHeaderContainerProps {
	name: string;
	mode: STEP_MODES;
	onStepClick?: () => void;
}

export const StepHeaderContainer = ({ name, mode, onStepClick }: StepHeaderContainerProps) => {
	const { titleColor, arrowColor, arrowBackgroundColor, arrowBorderColor } = useSpring({
		titleColor: getTitleColor(mode),
		arrowColor: getNumberColor(mode),
		arrowBackgroundColor: getNumberBackgroundColor(mode),
		arrowBorderColor: getNumberBorderColor(mode),
		from: {
			titleColor: TITLE_COLOR_DISABLE,
			arrowColor: ARROW_COLOR_DISABLE,
			arrowBackgroundColor: ARROW_BACKGROUND_COLOR_DISABLE,
			arrowBorderColor: ARROW_BORDER_COLOR_DISABLE,
		},
	});

	const transitions = useTransition(
		[{ isPreview: isStepModePreview(mode), isUncomplete: isStepModeUncompleted(mode) }],
		{
			from: {
				position: 'absolute',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				opacity: 0,
			},
			enter: { opacity: 1 },
			leave: { opacity: 0 },
		}
	);

	const handleHeaderClick = useCallback(() => {
		if (!(isStepModePreview(mode) || isStepModeUncompleted(mode)) || !onStepClick) {
			return;
		}

		onStepClick();
	}, [mode, onStepClick]);

	return (
		<StepHeaderView
			title={name}
			mode={mode}
			transitions={transitions}
			titleColor={titleColor}
			arrowColor={arrowColor}
			arrowBackgroundColor={arrowBackgroundColor}
			arrowBorderColor={arrowBorderColor}
			onStepClick={handleHeaderClick}
		/>
	);
};
