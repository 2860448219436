import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth/AuthSlice';
import capabilitiesReducer from './capabilities/CapabilitiesSlice';
import capabilityReducer from './capability/CapabilitySlice';
import integrationPatternReducer from './integrationPattern/IntegrationPatternSlice';
import integrationPatternConfigurationReducer from './integrationPatternConfiguration/IntegrationPatternConfigurationSlice';
import rosterReducer from './roster/RosterSlice';
import snackReducer from './snacks/SnackSlice';
import userReducer from './user/UserSlice';

export const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	snacks: snackReducer,
	roster: rosterReducer,
	capability: capabilityReducer,
	capabilities: capabilitiesReducer,
	integrationPattern: integrationPatternReducer,
	integrationPatternConfiguration: integrationPatternConfigurationReducer,
});
