import { StatusIconProtoProps } from '@nike/eds';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { SnackContainer } from 'components/common/SnackContainer';
import { useDispatch, useSelector } from 'config/redux/store';
import { SnackContext } from 'contexts/SnackContext';
import { selectSnacks } from 'services/store/snacks/SnackSelectors';
import { addSnack, deleteSnack } from 'services/store/snacks/SnackSlice';

interface SnackContextProviderProps {
	children: ReactNode;
}

export const SnackContextProvider = ({ children }: SnackContextProviderProps) => {
	const dispatch = useDispatch();
	const snacks = useSelector(selectSnacks);

	const createSnack = (status: StatusIconProtoProps['status'], content: string | ReactNode) => {
		dispatch(addSnack({ id: uuidv4(), status, content }));
	};

	const success = (content: string | ReactNode) => {
		createSnack('success', content);
	};

	const error = (content: string | ReactNode) => {
		createSnack('error', content);
	};

	const info = (content: string | ReactNode) => {
		createSnack('info', content);
	};

	const warning = (content: string | ReactNode) => {
		createSnack('warning', content);
	};

	const neutral = (content: string | ReactNode) => {
		createSnack('neutral', content);
	};

	const removeSnack = (snackId: string) => {
		dispatch(deleteSnack({ snackId }));
	};

	return (
		<SnackContext.Provider value={{ success, error, info, warning, neutral, removeSnack }}>
			<SnackContainer snacks={snacks} />
			{children}
		</SnackContext.Provider>
	);
};
