import { AegisClient } from '@nike/aegis-auth';
import { Box } from '@nike/eds';
import { ReactNode, useEffect } from 'react';

import { Loader } from 'components/common/Loader';
import { State, useDispatch, useSelector } from 'config/redux/store';
import { LoginPage } from 'pages/LoginPage';
import { selectHasInitStarted, selectIsLoggedIn } from 'services/store/auth/AuthSelectors';
import { AuthType, resetInit } from 'services/store/auth/AuthSlice';
import { selectUserName } from 'services/store/user/UserSelectors';
import { setUser } from 'services/store/user/UserSlice';
import { useAuth } from 'utils/hooks/auth';

interface LoginRequiredProps {
	client: AegisClient;
	children: ReactNode;
}

export const LoginRequired = ({ client, children }: LoginRequiredProps) => {
	const dispatch = useDispatch();
	const userName = useSelector(selectUserName);
	const isLoggedIn = useSelector(selectIsLoggedIn);
	const hasInitStarted = useSelector(selectHasInitStarted);
	const isRehydrated = useSelector((state: State) => state._persist.rehydrated);
	const { type, isLoading, isLoggedOut, loginComplete, idClaims } = useAuth(client);

	useEffect(() => {
		if (isRehydrated) {
			dispatch(resetInit());
		}
	}, [isLoggedIn, hasInitStarted, isRehydrated, dispatch]);

	useEffect(() => {
		if (isLoggedIn && !userName) {
			dispatch(setUser(idClaims));
		}
	}, [idClaims, isLoggedIn, userName, dispatch]);

	if (!loginComplete) {
		return <Loader type="full-screen" />;
	}

	if (isLoggedOut) {
		return <LoginPage authType={type as AuthType} isLoading={isLoading} />;
	}

	return (
		<Box data-testid="login-requred">
			{isLoading && <Loader type="full-screen--transparent" />}
			{children}
		</Box>
	);
};
