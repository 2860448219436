import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'config/redux/store';
import { UserDashboardPage } from 'pages/UserDashboardPage/UserDashboardPage';
import { DashboardTab } from 'pages/UserDashboardPage/UserDashboardPage.utils';
import { getCredentials, getIntegrations } from 'services/api/partner-roster/PartnerRosterApi';
import {
	selectCredentials,
	selectCredentialsError,
	selectIntegrations,
	selectIntegrationsError,
	selectIsLoadingDashboardData,
} from 'services/store/roster/RosterSelectors';
import { clearCredentialsError, clearIntegrationsError } from 'services/store/roster/RosterSlice';
import { selectUserEmail } from 'services/store/user/UserSelectors';
import { useSnack } from 'utils/hooks/snack';

export const UserDashboardPageContainer = () => {
	const snack = useSnack();
	const dispatch = useDispatch();
	const [activeDashboardTab, setActiveDashboardTab] = useState<DashboardTab>(DashboardTab.INTEGRATIONS);

	const userEmail = useSelector(selectUserEmail);
	const credentials = useSelector(selectCredentials);
	const credentialsError = useSelector(selectCredentialsError);
	const integrations = useSelector(selectIntegrations);
	const integrationsError = useSelector(selectIntegrationsError);
	const isLoadingDashboardData = useSelector(selectIsLoadingDashboardData);

	useEffect(() => {
		if (userEmail) {
			dispatch(getIntegrations({ email: userEmail }));
			dispatch(getCredentials({ email: userEmail }));
		}
	}, [userEmail, dispatch]);

	useEffect(() => {
		if (credentialsError) {
			snack.error(credentialsError);
			dispatch(clearCredentialsError());
		}

		if (integrationsError) {
			snack.error(integrationsError);
			dispatch(clearIntegrationsError());
		}
	}, [credentialsError, integrationsError, dispatch, snack]);

	const handleChangeConfigurationTab = (tab: DashboardTab) => setActiveDashboardTab(tab);

	return (
		<UserDashboardPage
			isLoading={isLoadingDashboardData}
			activeTab={activeDashboardTab}
			credentials={credentials}
			integrations={integrations}
			onChangeTab={handleChangeConfigurationTab}
		/>
	);
};
