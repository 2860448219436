import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getIntegrationPattern, getIntegrationPatternSchema } from 'services/api/mos-configurator/MosConfiguratorApi';
import { ResponseError } from 'services/types/Api';
import { IntegrationPatternDetails, Schema } from 'services/types/IntegrationPattern';

export interface IntegrationPatternState {
	isLoading: boolean;
	error: string;
	integrationPattern: IntegrationPatternDetails;
	integrationPatternSchema: {
		isLoading: boolean;
		error: string;
		schema: Schema | null;
	};
}

export const initialState: IntegrationPatternState = {
	isLoading: false,
	error: '',
	integrationPattern: {
		name: '',
		description: '',
		visualDescription: [],
		createdDate: '',
		updatedDate: null,
		schema: '',
		estimates: '',
		examples: [],
		integrationTypes: [],
	},
	integrationPatternSchema: {
		isLoading: false,
		error: '',
		schema: null,
	},
};

const integrationPatternSlice = createSlice({
	name: 'integrationPatternSlice',
	initialState,
	reducers: {
		resetIntegrationPatternState(state: IntegrationPatternState) {
			state.error = '';
			state.integrationPattern = initialState.integrationPattern;
		},
		clearIntegrationPatternError(state: IntegrationPatternState) {
			state.error = '';
		},
		clearIntegrationPatternSchemaError(state: IntegrationPatternState) {
			state.integrationPatternSchema.error = '';
		},
	},
	extraReducers(builder) {
		builder
			.addCase(getIntegrationPattern.pending, (state: IntegrationPatternState) => {
				state.isLoading = true;
			})
			.addCase(
				getIntegrationPattern.fulfilled,
				(state: IntegrationPatternState, { payload }: PayloadAction<IntegrationPatternDetails>) => {
					state.isLoading = false;
					state.integrationPattern = payload;
				}
			)
			.addCase(getIntegrationPattern.rejected, (state: IntegrationPatternState, { payload }) => {
				state.isLoading = false;
				state.error = (payload as ResponseError).errorMessage;
			})
			.addCase(getIntegrationPatternSchema.pending, (state: IntegrationPatternState) => {
				state.integrationPatternSchema.isLoading = true;
			})
			.addCase(
				getIntegrationPatternSchema.fulfilled,
				(state: IntegrationPatternState, { payload }: PayloadAction<Schema>) => {
					state.integrationPatternSchema.isLoading = false;
					state.integrationPatternSchema.schema = payload;
				}
			)
			.addCase(getIntegrationPatternSchema.rejected, (state: IntegrationPatternState, { payload }) => {
				state.integrationPatternSchema.isLoading = false;
				state.integrationPatternSchema.error = (payload as ResponseError).errorMessage;
			});
	},
});

export const { clearIntegrationPatternError, resetIntegrationPatternState, clearIntegrationPatternSchemaError } =
	integrationPatternSlice.actions;

export default integrationPatternSlice.reducer;
