import { StatusIconProtoProps } from '@nike/eds';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactNode } from 'react';

import { Snack } from 'services/types/common';

export interface SnacksState {
	snacks: Snack[];
}

export const initialState: SnacksState = {
	snacks: [],
};

const snackSlice = createSlice({
	name: 'snacks',
	initialState,
	reducers: {
		addSnack(
			state: SnacksState,
			{ payload }: PayloadAction<{ id: string; content: ReactNode | string; status: StatusIconProtoProps['status'] }>
		) {
			state.snacks = [...state.snacks, payload];
		},
		deleteSnack(state: SnacksState, { payload }: PayloadAction<{ snackId: string }>) {
			state.snacks = state.snacks.filter((snack) => snack.id !== payload.snackId);
		},
	},
});

export const { addSnack, deleteSnack } = snackSlice.actions;

export default snackSlice.reducer;
